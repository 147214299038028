import { CREATE_REGISTRATION, } from '../actions/action-types';


export type RegistrationType = any;

export interface WebpushAction {
  type: typeof CREATE_REGISTRATION,
  registration: RegistrationType
}



export interface WebpushState {
  subscription: RegistrationType
  registered: boolean
}

const webpush_state: WebpushState = {
  subscription: null,
  registered: false,
}

export function webpushReducer(state: WebpushState = webpush_state, action: WebpushAction) {
  if (action.type == CREATE_REGISTRATION) {
    return Object.assign({}, {
      subscription: action.registration,
      registered: true,
    });
  } else {
    return state;
  }
}
