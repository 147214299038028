import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { cloneDeep } from 'lodash'

import { APIError, handle_drf_form_error } from 'django-rest-react';
import { Categoria, ProfileData } from '../api/types';
import { displayUser, displayBadgePermesso, displayCategoria } from '../utils';
import { avatar_style, login_url } from '../globals';
import {
  FormGroupBootstrap, ErrorBlockGeneric,
  CategoriaMap, EventoMap, ConfirmedButton
} from '../components';
import {
  update_profile, update_avatar, update_avatar_serio,
} from '../api';
import { useCategoria, useLogin } from '../reducers';

interface FormValues {
  categorie_preferite: number[],
  ricevi_email: boolean,
  website: string,
  avatar: any,
  avatar_serio: any,
}

type AvatarData = Parameters<typeof update_avatar>[0];

const Profile = (props: {}) => {

  const login = useLogin();
  const [error, setError] = useState(null);
  const categorie = useCategoria(props);

  useEffect(() => {
    document.title = "Profilo - Owlitrack";
  }, [])


  if (!login.logged_in) {
    login_url();
  }

  const handle_avatar_helper = async (func: typeof update_avatar | typeof update_avatar_serio,
    values: AvatarData, actions: FormikActions<AvatarData>) => {
    func(values)
      .then(() => window.location.reload())
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
      .finally(() => actions.setSubmitting(false))
  }

  const handleSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    let submitting_data = cloneDeep(values);
    delete submitting_data.avatar;
    delete submitting_data.avatar_serio;
    update_profile(submitting_data as unknown as ProfileData)
      .then(() => window.location.reload())
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
      .finally(() => actions.setSubmitting(false))
  }


  const initial = {
    categorie_preferite: login.user.categorie_preferite,
    ricevi_email: login.user.ricevi_email,
    website: login.user.website,
  }
  let avatar_serio_content;
  if (login.user.avatar_serio) {
    avatar_serio_content = <img
      src={`/avatars-seri/${login.user.avatar_serio}/`}
      alt={displayUser(login.user.user)}
      style={avatar_style}
      className="img-thumbnail rounded"
    />
  } else {
    avatar_serio_content = <p>Non hai impostato un avatar serio.</p>
  }

  return (
    <div className="container">
      <h1 className="page-header">Il tuo profilo: {displayUser(login.user.user)}</h1>
      <div className={"d-flex align-items-center justify-content-center " +
        "justify-content-sm-between flex-sm-row flex-column px-2"}>
        <div>
          <p>Permessi: {displayBadgePermesso(login.user.permessi)}</p>
          <p>Categorie preferite: <CategoriaMap
            categorie={login.user.categorie_preferite} />
          </p>
          <p>Eventi assegnati: <EventoMap eventi={login.user.eventi_assegnati} />
          </p>
        </div>
        <div>
          {login.user.avatar &&
            <img src={`/avatars/${login.user.avatar}/`}
              alt={displayUser(login.user.user)} style={avatar_style}
              className="img-thumbnail rounded" />}
        </div>
      </div>
      <div className="my-2">
        <h3>Cambia categorie</h3>
        <ErrorBlockGeneric error={error} />
        <Formik
          initialValues={initial}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleReset }) => {
            return (
              <>
                <Form className="form needs-validation">
                  <FormGroupBootstrap
                    name="categorie_preferite" type="select-multiple"
                    choices={categorie}
                    displayChoice={(item: Categoria) => displayCategoria(item.id)}
                    help_text={"Tieni premuto Ctrl per selezionarne più di una."}
                  />
                  <FormGroupBootstrap
                    name="ricevi_email" type="checkbox"
                    help_text="Ricevi email di notifica sui post in cui sei coinvolto"
                  />
                  <FormGroupBootstrap
                    name="website" type="text"
                    help_text={"Il tuo sito web che comparirà su Gascal se " +
                      "sei nei top contributors. Se fai il simpaticone " +
                      "verrai pesantemente punito."}
                  />
                  <div className="d-flex justify-content-end">
                    <ConfirmedButton
                      type="warning"
                      onSubmit={handleReset}>
                      Reset
                    </ConfirmedButton>
                    <button type="submit" disabled={isSubmitting}
                      className="mx-1 btn btn-primary">Invia</button>

                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      <div className="my-2">
        <h3>Cambia avatar</h3>
        <Formik
          initialValues={{ _file: null }}
          onSubmit={(values, actions) => handle_avatar_helper(update_avatar, values, actions)}
        >
          {({ isSubmitting, handleReset, errors }) => {
            return (
              <>
                <Form className="form needs-validation">
                  <FormGroupBootstrap
                    name="_file" type="file"
                    displayName="File"
                  />
                  <div className="d-flex justify-content-end">
                    <ConfirmedButton
                      type="warning"
                      onSubmit={handleReset}>
                      Reset
                    </ConfirmedButton>
                    <button type="submit" disabled={isSubmitting}
                      className="mx-1 btn btn-primary">Invia</button>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
      <div className="my-2">
        <h3>Cambia avatar per Gascal</h3>
        <p>L'avatar che sceglierai qui sotto serve solo ad essere pubblicato su Gascal se comparirai nei top contributors. Mentre per l'avatar precedente puoi sbizzarrirti con il cancro, qui sei tenuto a mantenere del decoro e a mettere solo tue foto semi-serie. La foto verrà automaticamente ritagliata in modo da essere quadrata, per cui mettine una che ha già delle dimensioni sensate.</p>
        {avatar_serio_content}
        <Formik
          initialValues={{ _file: null }}
          onSubmit={(values, actions) =>
            handle_avatar_helper(update_avatar_serio, values, actions)}
        >
          {({ isSubmitting, handleReset }) => {
            return (
              <>
                <Form className="form needs-validation">
                  <FormGroupBootstrap
                    name="_file" type="file"
                    displayName="File"
                  />
                  <div className="d-flex justify-content-end">
                    <ConfirmedButton
                      type="warning"
                      onSubmit={handleReset}>
                      Reset
                    </ConfirmedButton>
                    <button type="submit" disabled={isSubmitting}
                      className="mx-1 btn btn-primary">Invia</button>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default Profile;
