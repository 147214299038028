import store from '../store';


// TODO: Questi tipi vanno sistemati, da qualche parte su developer mozilla
// saranno pur definiti
type SubscriptionType = any;
type RegistrationType = any;
declare var registration: RegistrationType;
type StatusType = "subscribe" | "unsubscribe";
import { sentry_log } from '../utils'
import { Severity } from '@sentry/types';


function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


export function subscribe(reg: RegistrationType) {
  return getSubscription(reg).then((subscription: SubscriptionType) => {
    postSubscribeObj('subscribe', subscription);
  })
    .catch((error: any) => {
      sentry_log(error, Severity.Error, { error_type: 'Subscription error' })
    });
}


function getSubscription(reg: RegistrationType) {
  let manager = reg.pushManager;
  if (!manager) {
    return new Promise((resolve, reject) => {
      reject("Push manager not available");
    });
  }
  return manager.getSubscription().then((subscription: SubscriptionType) => {
    let metaObj, applicationServerKey, options;
    // Check if Subscription is available
    if (subscription) {
      return subscription;
    }

    metaObj = document.querySelector('meta[name="django-webpush-vapid-key"]');
    // @ts-ignore
    applicationServerKey = metaObj.content;
    options = {
      userVisibleOnly: true
    };
    if (applicationServerKey) {
      // @ts-ignore
      options.applicationServerKey = urlB64ToUint8Array(applicationServerKey)
    }
    // If not, register one
    let registration = store.getState().webpush.subscription;
    return registration.pushManager.subscribe(options);
  });
}


export function postSubscribeObj(statusType: StatusType, subscription: SubscriptionType) {
  // Send the information to the server with fetch API.
  // the type of the request, the name of the user subscribing,
  // and the push subscription endpoint + key the server needs
  // to send push messages

  const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0]
    .toLowerCase(),
    data = {
      status_type: statusType,
      subscription: subscription.toJSON(),
      browser: browser,
      user_agent: navigator.userAgent,
      // group: subBtn.dataset.group
    };

  fetch("/webpushsave_information", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    credentials: 'include'
  })
    .then((response) => {
      // Check the information is saved successfully into server
      if ((response.status == 201) && (statusType == 'subscribe')) {
        // tutto ok, facciamo finta di niente.
      }

      // Check if the information is deleted from server
      if ((response.status == 202) && (statusType == 'unsubscribe')) {
        // Get the Subscription
        getSubscription(registration)
          .then((subscription: RegistrationType) => {
            // Remove the subscription
            subscription.unsubscribe()
              .then(() => {
                // Ok
              })
          })
      }
    })
}
