import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { displayBadgePermesso } from '../utils';
import { login_url } from '../globals'
import { useLogin } from '../reducers'


const About = () => {
  const login = useLogin();

  useEffect(() => {
    document.title = "Guida - Owlitrack";
  }, [])

  if (!login.logged_in) {
    login_url()
  }


  return (
    <div className="container">
      <h1 className="page-header">Guida</h1>
      <p>Questo sito è nato per raccogliere i problemi che vogliamo proporre alla gara a squadre dei fisici. Il sito è stato sviluppato principalmente da Fabio Zoratti usando un sacco di framework come Django Rest Framework e React. Essendo un sito sviluppato da un non professionista, è sicuro che ci saranno dei bug, per cui quando li trovate siete pregati di segnalarli con dovizia di particolari. Il posto più adatto per le segnalazioni sono le <a href="https://gitlab.com/sns-olifis/owlitrack/issues">issue</a> su GitLab. Il progetto è privato, per cui per poterlo vedere dovete farvi dare i permessi da Fabio. Potete scrivergli all'indirizzo <a href="mailto:fabio.zoratti@sns.it">fabio.zoratti@sns.it</a>.</p>
      <h3>Regole</h3>
      <h5>Autentica</h5>
      <p>Innanzitutto questo sito non esiste, a meno che qualcuno non voglia collaborare. Per fare il login è necessario un account Google e l'email deve essere esplicitamente autorizzata, per cui se non riesci a fare il login, <a href="mailto:fabio.zoratti@sns.it">contatta un amministratore</a>.</p>
      <h5 id="permessi">Permessi</h5>
      <p>Non tutti possono fare tutto, ma tutti possono proporre problemi.</p>
      <ul>
        <li>{displayBadgePermesso('x')}, queste persone non sono davvero su owlitrack, sono persone esterne che per un motivo o per l'altro non hanno il permesso di autenticarsi su questo sito ma hanno comunque proposto problemi e vogliamo tenerne traccia. Per esempio i professori di liceo che organizzano gli allenamenti scolastici.</li>
        <li>{displayBadgePermesso('b')}, quello di default, permette solo di vedere i problemi che ti vengono assegnati e quelli che hai proposto.</li>
        <li>{displayBadgePermesso('e')} ti permette di vedere tutti i problemi proposti per gli eventi che ti sono assegnati.</li>
        <li>{displayBadgePermesso('s')} ti permette di assegnare i problemi alla gente.</li>
        <li>{displayBadgePermesso('m')} ti permette di modificare i messaggi degli altri.</li>
        <li>{displayBadgePermesso('a')} tipo hai scritto tu il sito e possiedi la macchina su cui gira.</li>
      </ul>
      <h3>Proporre problemi</h3>
      <p>I problemi facili servono sempre!</p>
      <p>
        Per ogni tipo di gara, i problemi facili sono i più difficili da trovare. Spesso si fa l'errore di credere che un problema la cui soluzione è l’applicazione bovina di una tecnica sia facile; l'ideale sarebbe invece ridurre la tecnica al minimo (se non a zero)!
          <br />
          In più molti propositori, specialmente quelli alle prime armi, fanno fatica a valutare la reale difficoltà dei problemi che propongono. Per esempio, nel valutare la difficoltà di un problema proposto per febbraio, pensate due volte ai concorrenti di febbraio che avete conosciuto ai vostri tempi e che non sono mai stati a Senigallia. Ciò che state facendo è cercare di creare una gara il più possibile stimolante per loro, con parti accessibili anche per chi non ha mai sentito parlare di olimpiadi, non proporre problemi che avreste condiviso con i vostri compagni di IPhO durante gli spostamenti in pullman! Quando qualcuno commenta con un "ma non sarebbe un po' difficile...?" probabilmente ha ragione.
        </p>
      <h3>Citare le fonti</h3>
      <p>Non c'è assolutamente nulla di male nel trarre ispirazione da problemi "pubblicati" in passato sotto qualche forma: perfino se non lo fate non è improbabile che le vostre proposte siano simili a qualcosa di già visto da qualche parte! Se andate a spulciare qualche raccolta degli anni '70 per bambini ungheresi tradotta in cinese mandarino e ci trovate un problema interessante, non c'è veramente motivo per cui non possiate proporlo per la gara di febbraio anche così com'è. Tuttavia, è buona norma citare le vostre fonti di ispirazione; nessuno penserà male di voi se dichiarate apertamente di esservi ispirati al famoso dipolo massivo di Giacomo Hermes Gennaro, di un caro problema in cui si stima quanto è alta una montagna di un'ammissione in Normale o al punto 5.4.3.12.1 di una IPhO indiana. È però utilissimo per chi opera la selezione avere queste informazioni, anche perché una proposta può essere girata ai responsabili di altre gare, in cui l’originalità è molto più importante!</p>
      <h3>Votare</h3>
      <p>Dopo aver proposto il problema, votate anche voi per la sua bellezza o difficoltà. L'opinione del propositore è sempre utile.</p>
      <p>Per votare per un problema, cliccate sulle stelle sotto al suo testo. Il voto viene registrato automaticamente, e può essere cambiato in qualunque momento. Potete votare anche per solo una delle due categorie.
          Per quanto riguarda la scala di difficoltà, attenetevi ai criteri in <Link to={`/guida/difficolta/`}>livelli di difficoltà</Link>.
          <br />
          Quando votate cercate di lasciare anche un commento (vedi sezione successiva) con qualche considerazione sul problema. I soli voti non ci danno abbastanza informazioni sui problemi per essere veramente utili.
        </p>
      <h3>Commentare</h3>
      <p>
        Giudizi più dettagliati e osservazioni possono andare anche tra i commenti. Se le vostre osservazioni danno un forte aiuto a chi vuole tentare di risolvere il problema da solo, inseritele come testo nascosto usando il comando <code>[spoiler]testo[/spoiler]</code>.

          <br />
          Se qualcosa nel testo vi sembra poco chiaro, segnalatelo con un commento. Fateci sapere anche se avete già visto il problema da qualche parte o se avete specifiche obiezioni al fatto che venga selezionato, anche quando le avete implicitamente espresse tramite i voti di gradimento/difficoltà.
          <br />
          I migliori problemi nascono a volte dalla collaborazione di tutti: se vi vengono in mente modi per calibrare la difficoltà di un problema rendendolo più difficile o (molto meglio!) più facile, fatecelo sapere. Quando un problema vi sembra inadatto alla gara, provate a isolare gli elementi che vi piacciono e chiedetevi come si potrebbe incorporarli in una nuova proposta. Sul Owlitrack non si butta via (quasi) niente!
          <br />
          Al tempo stesso se vedete problemi che proprio non possono andare bene per alcuna gara, ad esempio perché già visti e rivisti, scrivetelo (educatamente) nei commenti: questo sarà utile a chi ha fatto la proposta, ma anche a tutti gli altri potenziali propositori.
        </p>
      <h3>Proposte di soluzione</h3>
      <p>I commenti sono un buon posto per discutere di strategie risolutive ("io l'ho fatto così", "c'è una strada più facile di questa?"). Se avete un attimo di tempo in più, potete scrivere un veloce sketch della vostra soluzione; anche conoscere tutte le strade possibili per risolverlo è un elemento utile per valutare la difficoltà di un problema.
          <br />
          Non esitate ad essere i primi a postare una soluzione: stimolerete chi ha trovato soluzioni diverse a proporre la propria. Non preoccupatevi dell'eventualità di sbagliare: i vostri errori possono esserci utilissimi e darci un'idea delle possibili difficoltà per i ragazzi che affronteranno la gara, aiutandoci a calibrare risposte multiple e/o chiarire punti equivoci dei testi!
        </p>
      <h3>Verificare</h3>
      <p>Per la gara a squadre, un elemento importante è che il risultato numerico di un problema venga verificato da almeno 3 persone. Per verificare un problema, dovete aprire il thread, andare in fondo e cliccare "Consegna risposta". Vi verrà chiesto di inserire il valore numerico della risposta e dopo averlo inserito saprete se è giusto o sbagliato. Ricordate che per i Fisici le risposte sono floating point.
          <br />
          Quando verificate un problema, non è obbligatorio che lo facciate in condizioni da gara; se l'ultimo passaggio è risolvere un'equazione trascendente, potete farlo fare a un computer. Chiaramente però i concorrenti non hanno questo lusso e i conti aumentano la difficoltà di un problema; tenetene conto quando votate!
        </p>
      <h3>Preferenze revisore</h3>
      <p>
        Potete specificare nella vostra pagina utente che tipo di problemi vorreste riguardare. Cerchiamo di darci un'occhiata quando vi diamo i problemi, ma potremmo sbagliarci.</p>
      <h3>Riservatezza</h3>
      <p>
        Queste proposte di problemi andranno molto probabilmente in gare future, quindi è meglio se non ne parlate in giro. Detto questo, siete comunque liberi di parlare dei problemi con il suo propositore o con altri nostri collaboratori su questo sito e risolverli in due-tre se preferite. L'unica accortezza è che quando verificate i risultati due persone che lavorano insieme contano come una verifica, non due.
        </p>
      <h3>Permessi</h3>
      <p>
        Non tutti gli account su Owlitrack sono uguali. E se dite "uno vale uno", ve la vedrete con l'amministratore.
          <br />
          Un account appena creato è di tipo {displayBadgePermesso('b')}, con privilegi di lettura/modifica/commento solo sui problemi che propone; sta poi a noi admin assegnare agli account di questo tipo dei set di proposte altrui da controllare e commentare. Solitamente quando c'è una decina di proposte cominciamo a smistarle e darle da verificare e votare ad altre persone. Se pensate che ci siamo dimenticati di voi, contattate il responsabile della gara e vi potrà sicuramente dare qualche altro problema.
          <br />
          Gli account {displayBadgePermesso('b')} possono essere "promossi" ad account con privilegi più alti ed acquisire privilegi automatici di visione/modifica/commento su tutte quante le proposte di una certa gara. Solitamente le persone che ci danno una mano da più anni vengono promosse. A un moderatore (a seconda delle gare) può essere comunque assegnato qualche problema da riguardare direttamente, ma allo stesso tempo può fare un giro anche tra le proposte altrui per commenti, voti o verifiche. I dettagli dei permessi si possono trovare più in alto in questa stessa pagina.
        </p>
      <h3>Costanti numeriche</h3>
      <p>Dato che siamo sporchi fisici, usiamo pure le calcolatrici e servono i dati numerici. Per le costanti fondamentali, dobbiamo uniformarci e utilizzarle tutti con lo stesso numero di cifre. Per questo, c'è <Link to={`/costanti/`}>una pagina</Link> in cui ho riportato le costanti importanti che possono servire. </p>
      <h3>Stile</h3>
      <p>Affinché un problema venga pubblicato in gara, è necessario che sia scritto quantomeno in italiano corretto. Cerchiamo poi di attenerci a linee stilistiche uniformi sia per il LaTeX che per il l'italiano. Tutte le informazioni sono riportate alla pagina sullo <Link to="/guidelines/">stile</Link>.</p>
      <h3>Altro</h3>
      <img src={`/static/images/gufo_giona.jpg`}
        alt="Buona Fortuna." className="mx-1 my-3 rounded img-thumbnail"
      />
    </div >
  );
}

export default About;
