import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThreadLista as Thread } from '../api/types';



interface IProps {
  thread_list: Thread[],
  pk: number,
}


export default class LinkProblema extends Component<IProps> {

  render() {
    const thread = this.props.thread_list.find(item => item.id == this.props.pk);
    if (thread) {
      return (
        <Link to={`/thread/problema/${thread.id}/`}>#{thread.id} - {thread.titolo}</Link>
      );
    } else {
      return <>Thread non disponibile</>
    }
  }
}
