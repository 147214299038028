import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { Risposta } from '../api/types';
import LoadingCircle from '../components/LoadingCircle';
import { usePartecipante, useThread } from '../reducers';


interface ownProps {
  risposta: Risposta
  link?: boolean,
}


const RispostaFeed = (props: ownProps) => {
  const problemi = useThread(props);
  const partecipanti = usePartecipante(props);

  let p; let domanda;
  p = problemi.find(item => (item.id == props.risposta.thread));
  if (!p) {
    return <LoadingCircle />;
  }
  const soluzione = p.problema.soluzioni.find(item => item.id == props.risposta.domanda);
  if (!soluzione) {
    return <LoadingCircle />;
  }
  const { label } = soluzione;
  domanda = ` alla domanda ${label} `
  const pill = props.risposta.corretto ?
    <span className="badge badge-success">Corretto</span> :
    <span className="badge badge-danger">Sbagliato</span>;
  const part = partecipanti.find(i => (i.id == props.risposta.partecipante));
  if (!part) {
    return <LoadingCircle />;
  }
  const link = props.link ? (
    <> al problema <Link to={`/thread/problema/${p.id}/`}>{p.titolo}</Link></>) :
    null
    ;

  return (
    <>
      <span className="my-2">
        <i className="fa fa-reply mx-2" />
        {nice_date(props.risposta.data)} {displayPartecipanteBadge(part)} ha risposto {pill}
        {domanda} {link}
      </span>
      <br />
    </>
  );
}

export default RispostaFeed;
