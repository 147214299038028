import React, { useEffect } from 'react'
import { useLogin } from '../reducers'
import { login_url, pdf_gimmy } from '../globals'


const LineeGuida = () => {
  const login = useLogin();
  useEffect(() => {
    document.title = `Linee guida stilistiche - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  return (
    <div className="container">
      <h1 className="page-header">Linee guida stilistiche</h1>
      <p>Questa è una lista di linee guida sul come scrivere il testo di un problema in modo che vada bene per essere pubblicato in gara a squadre</p>
      <ul>
        <li><b>Usare la forma impersonale</b>. Vanno bene frasi come <i>Si consideri un..., Si immagini che..., da trattarsi come...</i> e NON <i>Consideriamo un..., Immaginiamo che..., che tratteremo come..., Considera che..</i></li>
        <li><b>Evitare di scrivere equazioni all'interno delle righe del testo</b>. Le equazioni medio-lunghe vanno messe centrate fuori dal testo, tra due doppi dollari. Nel testo possono essere presenti definizioni come <pre>{"$\\beta\\equiv\\frac{v}{c}$"}</pre> e altre formule di lunghezza limitata</li>
        <li><b>Mettere la punteggiatura all'interno delle equazioni</b>. Se con l'equazione finisce la frase, bisogna mettere un punto prima del doppio dollaro finale. Se la frase continua nella riga successiva, bisogna mettere una virgola nella stessa posizione</li>
        <li><b>Spiegare cosa sono le grandezze all'interno di un'equzione</b>. Se una grandezza compare per la prima volta dentro un'equazione, bisogna dire di cosa si tratta se ancora non è stato fatto</li>
        <li><b>Evitare parole in lingua straniera</b>. Che senso c'è a scrivere <i>Box, Blast, Merge...</i> quando si può scrivere <i>Scatola, Esplosione, Fusione...</i></li>
        <li><b>Scrivere bene grandezze ed unità di misura</b>. In LaTeX, per scrivere il valore di una certa grandezza all'interno del testo del problema, bisogna usare questo costrutto <pre>{"$\\SI{Valore Numerico}{UdM}$"}</pre>
          valido per il Sistema Internazionale. Ad esempio, <pre>{"$200~\\frac{m}{s^2}$"}</pre> va scritto come <pre>{"$\\SI{200}{m/s^{2}}$"}</pre> mentre <pre>{"$1\\cdot 10^{-6}~ lm \\cdot m^{-2}$"}</pre> come <pre>{"$\\SI{1e-6}{lm\ m^{-2}}$"}</pre>
        </li>
        <li> <b>Evitare frasi troppo lunghe e costrutti complicati</b>. Chi legge deve capire cosa scrivete senza doversi scervellare troppo. Questo si ricollega all'ultimo punto:</li>
        <li> <b>IMPARATE A SCRIVERE IN ITALIANO, CANI!</b></li>
      </ul>
      <div className="row justify-content-center">
        <iframe style={{ width: '900px', height: '900px' }} src={pdf_gimmy}></iframe>
      </div>

    </div>
  )

}

export default LineeGuida
