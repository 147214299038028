import React from 'react'

import { WsStateFunctional, connectWsFunctional } from 'django-rest-react';
import { sentry_log } from '../utils';
import { refresh_shortlist } from './cache'
import { add_toast_action, delete_old_toasts, ToastType } from '../actions'
import store from '../store'

export { WebSocketState, useWebsocket } from 'django-rest-react'


export const connectBuildNotification = (obj: WsStateFunctional, kind: "testo" | "soluzioni", pk: number, incSeq: () => void, toast: ToastType, setLatexError: (_: string) => void) => {
  const msg_handler = (msg: any) => {
    if (msg["type"] == "build_ended") {
      incSeq();
      store.dispatch(delete_old_toasts(toast))

      const failed_toast = {
        title: "Compilazione fallita",
        message: <span className="text-danger"> La compilazione del TeX è fallita.Si guardi il log in basso.</span>,
        generating_object: "cusumano",
      }
      // Compilazione fallita
      if (!msg.json.hasOwnProperty(kind)) {
        console.warn("Ascoltando canale sbagliato", kind)
        return;
      }
      const arr = msg.json[kind];
      if (arr[1] != 0) {
        const errmsg = atob(arr[0]);
        setLatexError(errmsg);
        store.dispatch(add_toast_action(failed_toast))
      } else {
        setLatexError(null);
        store.dispatch(delete_old_toasts(failed_toast))
      }
      return;
    }
    sentry_log(new Error(`Messaggio dal ws BuildNotification non decodificato: ${msg}`))
  }

  return connectWsFunctional(obj, `/ws/problems/shortlist/${pk}/build/`, msg_handler);
}


export const connectModificaShortlist = (
  obj: WsStateFunctional,
  pk: number,
  setNewOrder: (_: string) => void,
  setConnectedPeople: (_: number, cusu: "add" | "del") => void,
) => {


  const msg_handler = (message: any) => {
    const _type = message["type"]
    if (_type == "new_ordine") {
      setNewOrder(message.ordine);
      return;
    }
    if (_type == "is_connected") {
      setConnectedPeople(message.partecipante, "add");
      return;
    }
    if (_type == "is_disconnected") {
      setConnectedPeople(message.partecipante, "del");
      return;
    }
    if (_type == "ping") {
      obj.ws.send(JSON.stringify({ 'type': "im_connected" }))
      return;
    }
    if (_type == "new_save") {
      refresh_shortlist();
      return;
    }
    sentry_log(new Error(`Messaggio dal ws 'connectModificaShortlist' non decodificato: ${message}`))
  }
  return connectWsFunctional(obj, `/ws/problems/shortlist/${pk}/`, msg_handler);
}
