import React, { useEffect } from 'react';

import { displayPartecipanteBadge } from '../utils';
import { CategoriaMap, CategoriaEventoMap } from '../components';
import { login_url } from '../globals'
import { usePartecipante, useLogin } from '../reducers'



const ListaPartecipanti = (props: {}) => {
  const partecipanti = usePartecipante(props);
  const login = useLogin();
  useEffect(() => {
    document.title = `Partecipanti - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  return (
    <div className="container">
      <h1 className="page-header">Lista degli iscritti a Owlitrack</h1>
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Nome</th>
              <th title="Eventi assegnati (ha senso solo se i permessi sono del tipo 'evento')">Eventi</th>
              <th title="Numero di problemi assegnati da correggere">Assegnati</th>
              <th title="Numero di problemi corretti">Risolti</th>
              <th title="Numero di problemi assegnati da correggere non ancora fatti">Da fare</th>
              <th title="Preferenze">Categorie</th>
            </tr>
          </thead>
          <tbody>
            {partecipanti.map(item => {
              return (
                <tr key={item.id}>
                  <td>{displayPartecipanteBadge(item)}</td>
                  <td><CategoriaEventoMap cats={item.eventi_assegnati} /></td>
                  <td>{item.quanti_assegnati}</td>
                  <td>{item.quanti_corretti}</td>
                  <td>{item.quanti_assegnati - item.quanti_assegnati_fatti}</td>
                  <td><CategoriaMap categorie={item.categorie_preferite} /></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListaPartecipanti;
