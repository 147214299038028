import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import { get_commenti_shortlist } from '../api'
import { Commento as TCommento, VersioneShortlist, ThreadLista } from '../api/types'
import { Commento, LoadingCircle } from '../components'
import { nice_date } from '../utils'
import { useThread } from '../reducers';

interface ownProps {
  threadpk: number,
  versioni: VersioneShortlist[]
}

const TimeFeedShortlist = (props: ownProps) => {
  const threadproblemi = useThread(props);
  const [commenti, setCommenti] = useState([])

  useEffect(() => {
    get_commenti_shortlist({ thread: props.threadpk }).then(setCommenti)
  }, [])

  const ccommenti = commenti.map(commento => {
    return {
      date: commento.data, content: <Commento
        commento={commento} shortlist={true}
      />
    }
  });
  const modifiche = props.versioni.map(versione => {
    return {
      date: versione.data, content:
        <h6 key={versione.id}><i className="fa fa-check-square" /> {nice_date(versione.data)} Problemi in lista: {versione.lista_problemi.map(probpk => {
          const thread = threadproblemi.find(thread => thread.problema.id === probpk);
          return thread ? <Link to={`/thread/problema/${thread.id}/`} key={probpk}>#{thread.id}</Link> : <LoadingCircle />
        }
        )}</h6>
    };
  });
  const timefeed = ccommenti
    .concat(modifiche)
    .sort((a, b) => {
      return (new Date(a.date)).getTime() - (new Date(b.date)).getTime();
    })
    .map((item, idx) => <React.Fragment key={idx}>{item.content}</React.Fragment>);
  return (
    <div className="mt-2">
      {timefeed}
    </div>
  );
}

export default TimeFeedShortlist;
