import React from 'react';
import { Link } from 'react-router-dom';

import {
  displayPartecipanteBadge, nice_date,
} from '../utils';
import { ThreadLista, CitazioneThread, ThreadShortlist } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante, useShortlist, useThread } from '../reducers';


interface ownProps {
  citazione: CitazioneThread,
  link?: boolean,
}


type Thread = ThreadShortlist | ThreadLista


const NameAndLink = (pk: number, allthreads: Thread[]) => {
  const thread = allthreads.find(thr => thr.id === pk);
  if (!thread) return null;

  const link = thread.tipologia === "s" ?
    `/shortlist/${pk}/` :
    `/thread/problema/${pk}/`;

  return <Link to={link}>{thread.titolo}</Link>
}

const CitazioneThreadFeed = (props: ownProps) => {
  const { citazione } = props;
  const thread = useThread(props);
  const partecipanti = usePartecipante(props);
  const shortlist = useShortlist(props);

  const { partecipante } = citazione;
  const part = partecipanti.find(i => (i.id == partecipante));
  if (!part) {
    return <LoadingCircle />;
  }

  const allthread = (thread as Thread[]).concat(shortlist || [])
  const citante = NameAndLink(citazione.thread_originale, allthread);
  const citato = NameAndLink(citazione.thread, allthread);

  if (!citato) {
    return <p>Non hai il permesso di vedere questo thread</p>;
  }

  return (
    <>
      <span className="my-2">
        <i className="fa fa-quote-left mx-2" />
        {nice_date(citazione.data)} {displayPartecipanteBadge(part)} ha citato il thread {citato} nel thread {citante}.
      </span>
      <br />
    </>
  );
}

export default CitazioneThreadFeed;
