import React from 'react';
import { Link } from 'react-router-dom'

import {
  nice_date, displayPartecipante,
} from '../utils';
import { Commento } from '../api/types';
import { canEditCommento, canEditProblema } from '../globals';
import { Partecipante, MdMath, AllegatoMap, BottoneVersioniCommento, LoadingCircle } from '../components';
import { useAllegato, useLogin, useShortlist, useThread } from '../reducers';


interface ownProps {
  commento: Commento
  shortlist?: boolean
}


const CommentoView = (props: ownProps) => {
  const allegati = useAllegato(props);
  const login = useLogin();

  const last = props.commento.versioni[props.commento.versioni.length - 1];
  const first = props.commento.versioni[0];
  const data_pubblicazione = nice_date(new Date(first.data));
  const data_modifica = nice_date(new Date(last.data));
  const modificato_da_altri = last.partecipante != props.commento.partecipante;
  const modificato = (props.commento.versioni.length > 1)
  const extra = modificato_da_altri ? ` da ${displayPartecipante(last.partecipante)}` : "";
  const testo_data = modificato ?
    `Pubblicato: ${data_pubblicazione}. Modificato l'ultima volta${extra}: ${data_modifica}` :
    `${data_pubblicazione}`;
  const modifica_link = props.shortlist ?
    `/shortlist/${props.commento.thread}/commento-edit/${props.commento.id}/` :
    `/thread/problema/${props.commento.thread}/commento-edit/${props.commento.id}/`;
  const bottone_modifica = ((login.user.id == props.commento.partecipante) ||
    canEditCommento(login.user.permessi)) ?
    <Link to={modifica_link}>
      <button className="btn btn-warning mr-3" title="Edit">
        <i className="fa fa-edit" />
      </button>
    </Link> : null;
  const bottone_versioni = modificato ?
    <BottoneVersioniCommento commento={props.commento} />
    : null;
  return (
    <div className="card my-1">
      <div className="card-body">
        <div className="container">
          <div className="row pt-2">
            <div className="col-lg-9 pl-4 d-flex align-items-start flex-column" style={{ overflowX: "auto" }}>
              <div>
                <MdMath source={last.testo} />
              </div>
              <div className="row mb-4">
                <div className="d-inline-flex flex-row">
                  <AllegatoMap allegati={last.allegati} />
                </div>
              </div>
              <div className="row mt-auto">
                {bottone_modifica}
                {bottone_versioni}
              </div>
            </div>
            <div className="col-lg-3 no-gutters">
              <Partecipante partecipante={props.commento.partecipante} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer py-1">
        <small>{testo_data}</small>
      </div>
    </div>
  );
}

export default CommentoView;
