import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, } from 'formik';
import { useNavigate } from 'react-router-dom'

import { APIError, PermissionDenied, handle_drf_form_error } from 'django-rest-react'
import { FormGroupBootstrap, ConfirmedButton, ErrorBlockGeneric } from '../components'
import {
  displayEvento, displayPartecipante,
} from '../utils'
import { Evento, ThreadShortlist, Partecipante, } from '../api/types'
import { post_nuova_shortlist, refresh_shortlist } from '../api'
import { canSeeShortlist, login_url } from '../globals';
import { useEvento, useLogin, usePartecipante, useShortlist } from '../reducers';



type FormValues = Parameters<typeof post_nuova_shortlist>[0]["shortlist"];


const NuovaShortlist = (props: {}) => {
  const login = useLogin();
  const [error, setError] = useState(null);

  const eventi = useEvento(props);
  const shortlist = useShortlist(props);
  const partecipanti = usePartecipante(props);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Nuova shortlist - Owlitrack";
  }, [])


  if (!login.logged_in) {
    login_url()
  }
  if (!canSeeShortlist(login.user)) {
    throw new PermissionDenied({
      message: "Non hai abbastanza permessi per vedere le shortlist."
    })
  }


  const handleSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    post_nuova_shortlist({ shortlist: values, })
      .then(ans => {
        refresh_shortlist();
        navigate(`/shortlist/${ans.id}/`);
      })
      .catch((e: APIError) => handle_drf_form_error(e, actions, setError))
      .finally(() => actions.setSubmitting(false))
  }



  const initialValues: FormValues = {
    titolo: "", target_numero_problemi: 12, evento: undefined as number, parent: undefined as number,
    versioni: [], extra_contributors: [], copertina_custom: null,
  }
  return (
    <div className="container">
      <h1 className="page-header">Nuova shortlist</h1>
      {error && <ErrorBlockGeneric error={error} />}
      <Formik
        initialValues={initialValues} onSubmit={handleSubmit}
      >
        {({ handleReset, isSubmitting }) => {
          return (
            <Form className="needs-validation">
              <FormGroupBootstrap
                name="titolo"
                type="text"
              />
              <FormGroupBootstrap
                name="evento"
                type="select"
                help_text="La shortlist può essere pensata per uno specifico evento, ma anche no. Puoi lasciarlo in bianco."
                choices={eventi}
                displayChoice={(item: Evento) => displayEvento(item.id)}
              />
              <FormGroupBootstrap
                name="target_numero_problemi"
                type="number"
              />
              <FormGroupBootstrap
                name="parent"
                type="select"
                choices={shortlist.map(item => item.shortlist)}
                displayChoice={(item: ThreadShortlist['shortlist']) => item.titolo}
                help_text="Puoi creare una shortlist anche come shortlist derivata da un'altra. Non è obbligatorio, puoi lasciare il campo vuoto"
              />
              <FormGroupBootstrap
                name="extra_contributors"
                type="select-multiple"
                choices={partecipanti}
                displayChoice={(item: Partecipante) => displayPartecipante(item.id)}
                help_text="Le shortlist possono essere viste da moderatori, admin e persone specifiche indicate in questo campo."
              />
              <div className="d-flex justify-content-end">
                <ConfirmedButton
                  type="warning"
                  onSubmit={handleReset}
                >
                  Reset
                </ConfirmedButton>
                <button type="submit" disabled={isSubmitting}
                  className="mx-1 btn btn-primary">Crea</button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NuovaShortlist;
