import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { Commento, ThreadLista, ThreadShortlist } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante, useShortlist, useThread } from '../reducers';


type Thread = ThreadShortlist | ThreadLista;

const CommentoFeed = (props: { commento: Commento }) => {
  const partecipanti = usePartecipante(props);
  const problemi = useThread(props);
  const shortlist = useShortlist(props);

  const originale = (problemi as Thread[]).concat(
    shortlist || []).find(
      (item: Thread) => item.id == props.commento.thread)

    ;
  const part = partecipanti.find(i => i.id == props.commento.partecipante);
  if (!originale || !part) {
    return <LoadingCircle />
  }

  const is_shortlist = originale.tipologia == "s";
  const link = is_shortlist ?
    <> la shortlist <Link to={`/shortlist/${originale.id}/`}>{originale.titolo}</Link></>
    : <> il problema <Link to={`/thread/problema/${originale.id}/`}>{originale.titolo}</Link></>

  return (
    <>
      <span className="my-2">
        <i className="fa fa-comment-dots mx-2" />
        {nice_date(props.commento.data)} {displayPartecipanteBadge(part)}
        ha commentato {link}
      </span>
      <br />
    </>
  );
}
export default CommentoFeed;
