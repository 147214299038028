import React from 'react';

import { useSelectorRefresh } from 'django-rest-react';
import { Tile, LoadingCircle } from '../components'
import { Allegato } from '../api/types';
import { Reverse } from '../reducers'


interface ownProps {
  allegati: number[],
}

const AllegatoMap = (props: ownProps) => {

  const allegati_obj = useSelectorRefresh(props, Reverse.Allegato) as Allegato[];
  return (
    <>
      {props.allegati.map(item => {
        const ogg = allegati_obj.find(i => (i.id == item));
        if (!ogg) {
          return <LoadingCircle key={item} />
        }
        let type;
        const file_types = [
          { type: "png", name: "image" },
          { type: "jpg", name: "image" },
          { type: "jpeg", name: "image" },
          { type: "svg", name: "image" },
          { type: "pdf", name: "pdf" },
          { type: "zip", name: "archive" },
          { type: "tar", name: "archive" },
          { type: "gz", name: "archive" },
          { type: "csv", name: "csv" },
        ];
        file_types.some((val) => {
          // @ts-ignore
          if (ogg._file.match(new RegExp(`\.${val.type}`, 'g'))) {
            type = "-" + val.name;
            return true;
          }
        });
        const icon = type ? `fa-file${type}` : "fa-file";
        return (
          <Tile key={item}
            url={`/attachments/${item}/`}
            text={`${ogg.titolo}`}
            color="pumpkin" icon={icon}
            size="tile-small"
            iconSize="icon fa-sm"
            margin={1.}
            hardlink
          />
        )
      })}
    </>
  );
}


export default AllegatoMap;
