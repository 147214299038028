import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { get_bozze } from '../api';
import { nice_date } from '../utils';
import { CategoriaMap } from '../components';
import { login_url } from '../globals'
import { useLogin } from '../reducers'


const Bozze = () => {

  const login = useLogin();
  const [bozze, setBozze] = useState([]);

  useEffect(() => {
    document.title = "Bozze - Owlitrack";
    get_bozze().then(setBozze)
  }, []);

  if (!login.logged_in) {
    login_url()
  }
  return (
    <div className="container">
      <h1 className="page-header">Le tue bozze</h1>
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Titolo</th>
              <th>Categorie</th>
              <th>Ultima modifica</th>
            </tr>
          </thead>
          <tbody>
            {bozze.map(item => {
              return (
                <tr key={item.id}>
                  <td><Link to={`/drafts/${item.id}/`}>{item.titolo}</Link></td>
                  <td>
                    <CategoriaMap categorie={item.categoria} />
                  </td>
                  <td>{nice_date(item.ultima_modifica)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Bozze;
