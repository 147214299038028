import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { CommentoVersioneFeed as TCommentoVersioneFeed, ThreadLista } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante, useShortlist } from '../reducers';


interface ownProps {
  commento_versione: TCommentoVersioneFeed
  problemi: Omit<ThreadLista, "letto" | "bellezza_medio" | "difficolta_medio" | "risposto_totale" | "visualizzazioni" | "ultima_modifica" | "risposto_giusto">[]
}


const CommentoVersioneFeed = (props: ownProps) => {
  const partecipanti = usePartecipante(props);
  const shortlist = useShortlist(props);
  const { problemi, commento_versione } = props;

  const thread = [...problemi, ...shortlist].find(thr => thr.id === commento_versione.thread)
  const titolo = thread?.titolo;
  const part = partecipanti.find(i => i.id == commento_versione.partecipante);
  const url = thread?.tipologia == "s" ?
    `/shortlist/${commento_versione.thread}/` :
    `/thread/problema/${commento_versione.thread}/`;
  if (!part) {
    return <LoadingCircle />
  }
  return (
    <>
      <span className="my-2">
        <i className="fa fa-edit ml-2" />
        <i className="fa fa-comment-dots mx-2" />
        {nice_date(commento_versione.data)} {displayPartecipanteBadge(part)}
        ha modificato un commento nel thread <Link to={url}>
          {titolo}</Link>
      </span>
      <br />
    </>
  );
}

export default CommentoVersioneFeed;
