import React from 'react';
import { Link } from 'react-router-dom';

import { displayUser, displayColorePermesso, displayBadgePermesso } from '../utils';
import { Partecipante } from '../api/types';
import { usePartecipante } from '../reducers';

const avatar_style = {
  maxWidth: 100
}

const card_size = {
  minWidth: 200,
  minHeight: 100,
}

const PartecipanteComp = (props: { partecipante: number }) => {
  const partecipanti = usePartecipante(props)
  let name_classes = "mb-2 text-"
  const partecipante = partecipanti.find(item => (item.id == props.partecipante))
  if (!partecipante) {
    return <h1>Non trovato</h1>
  }
  let extra = displayColorePermesso(partecipante.permessi);
  name_classes += extra;
  const name = displayUser(partecipante.user);
  return (
    <div className="card" style={card_size}>
      {partecipante.avatar &&
        <img className="card-img-top rounded my-1 mx-auto d-block"
          src={`/avatars/${partecipante.avatar}/`}
          alt={name}
          style={avatar_style} />}
      <div className="card-body">
        <Link to={`/utente/${partecipante.id}/`}>
          <h6 className={name_classes}>
            {displayBadgePermesso(partecipante.permessi)} {name}
          </h6>
        </Link>
      </div>
    </div>
  );
}

export default PartecipanteComp;
