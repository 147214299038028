import React from 'react';
import { Link } from 'react-router-dom'

import { displayPartecipante } from '../utils';
import { usePartecipante } from '../reducers';


const PartecipanteMap = (props: { partecipanti: number[] }) => {
  usePartecipante(props);
  return (
    <>
      {props.partecipanti.map(item => {
        return <Link to={`/utente/${item}/`} key={item}>
          <span className="ml-1">{displayPartecipante(item)}</span><br />
        </Link>
      })}
    </>
  );
}
export default PartecipanteMap;
