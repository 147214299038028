import React, { useRef, useState } from 'react';
import { Popover, Overlay, Button } from 'react-bootstrap'
import { FormikHelpers as FormikActions } from 'formik'

interface IProps<T> {
  onSubmit: (values?: T, actions?: FormikActions<T>) => any,
  type: "success" | "warning" | "danger" | "info",
  children: React.ReactNode
}



export default function ConfirmedButton<T>(props: IProps<T>) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>
      <Button
        className={`mx-1 btn btn-${props.type}`} title="ConfirmRequired" onClick={() => setShow(!show)}
        ref={target}>
        {props.children}
      </Button>
      <Overlay
        placement="top"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => {
          return (
            <Popover id={`conferma`} {...old}>
              <Popover.Title>
                <span className="mr-2">
                  <i className="fa fa-times text-muted" onClick={() => setShow(false)} />
                </span>
                Conferma l'azione
              </Popover.Title>
              <Popover.Content>
                <p>Sei sicuro?</p>
                <div className="d-flex flex-content-between">
                  <Button
                    className="btn btn-success mx-1" title="Conferma" onClick={
                      () => {
                        props.onSubmit();
                        setShow(false);
                      }}>
                    Conferma
                  </Button>
                  <Button className="btn btn-warning mx-1" title="Annulla"
                    onClick={() => setShow(false)}>
                    Annulla
                  </Button>
                </div>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
}
