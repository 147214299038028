import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date, displayEvento } from '../utils';
import { AssegnazioneEvento } from '../api/types';
import LoadingCircle from '../components/LoadingCircle';
import { useEvento, usePartecipante, useThread } from '../reducers';


interface ownProps {
  assegnazione: AssegnazioneEvento
}


const AssegnazioneEventoFeed = (props: ownProps) => {
  const { assegnazione } = props;
  const partecipanti = usePartecipante(props);
  const eventi = useEvento(props);
  const problemi = useThread(props);

  const p = problemi.find(i => (i.id == assegnazione.thread));
  let titolo = "";
  if (p) {
    titolo = p.titolo;
  }
  const part1 = partecipanti.find(i => i.id == assegnazione.assegnatore);
  const part2 = eventi.find(i => i.id == assegnazione.evento);
  if (!part1 || !part2) {
    return <LoadingCircle />
  }
  const testo_1 = assegnazione.aggiunto ? "ha assegnato il problema" :
    "ha tolto il problema";
  const testo_2 = assegnazione.aggiunto ? "all'evento " : "dall'evento";
  const badge = assegnazione.aggiunto ? "fa-hand-point-right" : "fa-hand-point-left";
  return (
    <>
      <span className="my-2">
        <i className={`fa text-danger ${badge} mx-2`} />
        {nice_date(assegnazione.data)} {displayPartecipanteBadge(part1)}
        {testo_1}
        <span className="mx-1">
          <Link to={`/thread/problema/${assegnazione.thread}/`}>
            {titolo}
          </Link>
        </span>
        {testo_2} {displayEvento(part2.id)}
      </span>
      <br />
    </>
  );
}


export default AssegnazioneEventoFeed;
