import React, { useRef, useState } from 'react';
import { Popover, Overlay, Button } from 'react-bootstrap'
import { diffWords } from 'diff'

import MdMath from '../components/MdMath';
import { nice_date } from '../utils';
import { Commento } from '../api/types';

interface IProps {
  commento: Commento
}


export default function BottoneVersioniCommento(props: IProps) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button className="btn btn-info mr-3" title="Versioni" onClick={() => setShow(!show)}>
        <i className="fa fa-copy"
          ref={target} /></Button>
      <Overlay
        placement="bottom"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => {
          return <Popover id={`versioni`} {...old}>
            <Popover.Title>
              <span className="mr-2">
                <i className="fa fa-times text-muted" onClick={() => setShow(false)} />
              </span>
                Versioni
              </Popover.Title>
            <Popover.Content>
              {props.commento.versioni.map((versione, idx) => {
                let elem = null;
                if (idx != 0) {
                  const old = props.commento.versioni[idx - 1];
                  const diff = diffWords(old.testo, versione.testo);
                  elem = diff.map(part => {
                    const color = part.added ? 'success' :
                      part.removed ? 'danger' : null;
                    return color ? <span className={`text-${color}`}>{part.value}</span> : part.value
                  })
                }

                return <div className="card" key={versione.id}>
                  <div className="card-header">
                    <h6>{nice_date(versione.data)}</h6>
                  </div>
                  <div className="card-body">
                    <h6 className="page-header">Testo renderizzato</h6>
                    <MdMath source={versione.testo} />
                    {elem &&
                      <>
                        <h6 className="page-header">Sorgente con modifiche evidenziate</h6>
                        {elem}
                      </>
                    }
                  </div>
                </div>
              })}
            </Popover.Content>
          </Popover>
        }}
      </Overlay>
    </>
  );
}
