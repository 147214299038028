import React, { useEffect } from 'react';
// @ts-ignore
import StarRatings from 'react-star-ratings';

import { star_style, login_url } from '../globals';
import { useLogin } from '../reducers'

const GuidaDifficolta = () => {
  const login = useLogin();
  useEffect(() => {
    document.title = `Guida difficoltà - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }
  return (
    <div className="container">
      <h1 className="page-header">Guida alla difficoltà dei problemi.</h1>
      <ul>
        <li>
          <StarRatings
            {...star_style}
            rating={1}
          />
            : i 1/2 problemi più facili della gara, dovrebbero saperli fare tutti i membri di una squadra;
          </li>
        <li>
          <StarRatings
            {...star_style}
            rating={2}
          />
            : problema facile/basic ma che richiede una conoscenza che non tutti i membri della squadra potrebbero avere (oppure problema standard ma con un po' di conti);
          </li>
        <li>
          <StarRatings
            {...star_style}
            rating={3}
          />
            : solo 1/2 persone per squadra sono in grado di affrontare il problema, vuoi per conoscenze o per difficoltà intrinseca;
          </li>
        <li>
          <StarRatings
            {...star_style}
            rating={4}
          />
            : solo pochi concorrenti tra tutte le squadre sono in grado di fare il problema;
          </li>
        <li>
          <StarRatings
            {...star_style}
            rating={5}
          />
            : il problema non può stare in questa gara perché è troppo difficile.
          </li>
      </ul>
      <p>
        Come abbondanza di problemi, usando questa scala, io auspicherei in ordine decrescente: 2 stelle, 3 stelle, 1 stella, 4 stelle, 5 stelle.</p>
    </div>
  );
}


export default GuidaDifficolta;
