import React, { useEffect, useState } from 'react';

import { get_bellezza, get_difficolta, get_problema_thread } from '../api';
import { displayPartecipanteBadge } from '../utils';
import { LoadingCircle } from '../components';
import { login_url } from '../globals'
import { useParams } from 'react-router-dom'
import { useLogin, usePartecipante } from '../reducers'


const VotiThreadProblema = (props: {}) => {
  const [bellezza, setBellezza] = useState([]);
  const [difficolta, setDifficolta] = useState([]);
  const [thread, setThread] = useState(null);
  const partecipanti = usePartecipante(props);
  const params = useParams<{ pk: string }>();
  const pk = parseInt(params.pk);

  useEffect(() => {
    document.title = `Voti #${pk}`
    // Questo è il pk del thread. A noi serve del problema
    get_problema_thread(pk).then(thr => {
      setThread(thr);
      const pk = thr.problema.id;
      get_bellezza({ problema: pk }).then(setBellezza)
      get_difficolta({ problema: pk }).then(setDifficolta)
    });
  }, [])
  const login = useLogin();
  if (!login.logged_in) {
    login_url()
  }

  return (
    <div className="container">
      <h1 className="page-header">Voti assegnati a {thread?.titolo} ({thread?.problema.titolo})</h1>
      <h3>Bellezza</h3>
      <ul>
        {bellezza.map(item => {
          const part = partecipanti.find(i => i.id == item.partecipante);
          if (!part) {
            return <LoadingCircle key={item.id} />
          }
          return (
            <li key={item.id}>{item.voto} - {displayPartecipanteBadge(part)} ha votato {item.voto}</li>
          );
        })}
      </ul>
      <h3>Difficoltà</h3>
      <ul>
        {difficolta.map(item => {
          const part = partecipanti.find(i => i.id == item.partecipante);
          if (!part) {
            return <LoadingCircle key={item.id} />
          }
          return (
            <li key={item.id}>{item.voto} - {displayPartecipanteBadge(part)} ha votato {item.voto}</li>
          );
        })}
      </ul>
    </div>
  );
}


export default VotiThreadProblema;
