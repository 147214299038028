import { Permessi as Permesso, UserWhoami, Problema, Shortlist } from '../api/types';


export function canEditProblema(user: UserWhoami, problema: Problema, shortlists: Shortlist[]): boolean {
  switch (user.permessi) {
    case 'a': case 'm': return true;
    default: {
      for (const short of shortlists) {
        if (short.versioni[short.versioni.length - 1].lista_problemi.includes(problema.id)) return true;
      }
      return false;
    }
  }
}

export function canEditCommento(p: Permesso) {
  switch (p) {
    case 'a': case 'm': return true;
    default: return false;
  }
}

export function canSeeVoti(p: Permesso): boolean {
  switch (p) {
    case 'a': case 'm': case 's': return true;
    default: return false;
  }
}


export function canSeeRisposte(p: Permesso): boolean {
  switch (p) {
    case 'a': case 'm': return true;
    default: return false;
  }
}


export function canAssegnareProblemi(p: Permesso): boolean {
  switch (p) {
    case 'a': case 'm': case 's': return true;
    default: return false;
  }
}


export function canSetSmistatoreFlag(p: Permesso): boolean {
  switch (p) {
    case 'a': case 'm': case 's': return true;
    default: return false;
  }
}


export function canSeeShortlist(p: UserWhoami): boolean {
  switch (p.permessi) {
    case 'a': case 'm': return true;
    default: return p.shortlists_visibili.length > 0;
  }
}


export function canAssegnare(p: UserWhoami): boolean {
  switch (p.permessi) {
    case 'a': case 'm': case 's': return true;
    default: return false;
  }
}

export function canSeeSoluzioneSpiegata(p: UserWhoami): boolean {
  switch (p.permessi) {
    case 'a': case 'm': return true;
    default: return false;
  }
}
