import React from 'react';
import { Link } from 'react-router-dom'

import { displayEvento } from '../utils';
import { useEvento } from '../reducers';


const EventoMap = (props: { eventi: number[] }) => {
  useEvento(props);
  return (
    <>
      {props.eventi.map(item => {
        return <Link to={`/thread/problema/evento/${item}/`} key={item}>
          <span className="ml-1">{displayEvento(item)}</span><br />
        </Link>
      })}
    </>
  );
}
export default EventoMap;
