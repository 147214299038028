import React, { useEffect, useState } from 'react';

import {
  FormGroupBootstrap, ConfirmedButton,
} from '../components';
import { GIORNI_FEED, login_url } from '../globals';
import { urlencode_iso8601_date, } from '../utils';
import { useLogin, usePartecipante, useThread } from '../reducers';
import { Form, Formik, FormikHelpers } from 'formik';
import { emptyFeed, fetchFeed, renderFeed } from '../utils/feed';


interface FormValues {
  data__lte: string,
  data__gte: string,
}

const filterFactory = (val: FormValues) => {
  return {
    commenti: val,
    commenti_shortlist: val,
    risposte: val,
    assegnazione: val,
    modifiche_commenti: val,
    modifiche_problemi: val,
    citazioni: val,
  }
}

const UltimeModifiche = (props: {}) => {
  const login = useLogin();
  const _ = usePartecipante(props);
  const problemi = useThread(props);
  const [feed, setFeed] = useState(emptyFeed);

  let last_date = new Date();
  last_date.setDate(last_date.getDate() - GIORNI_FEED);


  const filterProbFact = (values: FormValues) => (prob: ReturnType<typeof useThread>[0]) => {
    const probdate = new Date(prob.data_inizio)
    const inizio = new Date(values.data__gte)
    const fine = new Date(values.data__lte)

    return inizio.getTime() < probdate.getTime() && probdate.getTime() < fine.getTime()
  }
  const initialValues = {
    data__gte: urlencode_iso8601_date(last_date),
    data__lte: urlencode_iso8601_date(new Date()),
  }

  useEffect(() => {
    document.title = `Ultime notizie - Owlitrack`;
    fetchFeed(filterFactory(initialValues)).then(setFeed)
  }, [])



  if (!login.logged_in) {
    login_url()
  }

  return (
    <div className="container">
      <h1 className="page-header">Ultime modifiche</h1>
      <Formik initialValues={initialValues} onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        fetchFeed(filterFactory(values)).then(setFeed);
        actions.setSubmitting(false);
      }}
      >
        {({ isSubmitting, handleReset, values }) => {
          const rendered_feed = renderFeed(feed, problemi, filterProbFact(values), false, true);
          return (
            <>
              <Form className="form needs-validation">
                <FormGroupBootstrap
                  name="data__gte" type="datetime"
                  displayName="Avvenuti prima del"
                />

                <FormGroupBootstrap
                  name="data__lte" type="datetime"
                  displayName="Avvenuti dopo il"
                />
                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  <button type="submit" disabled={isSubmitting}
                    className="mx-1 btn btn-primary">Invia</button>
                </div>
              </Form>
              <h3>Avvenimenti</h3>
              <div className="mb-3">
                {rendered_feed.length == 0 && <p>Nessun evento corrispondente al filtro</p>}
                {rendered_feed.reverse()}
              </div>

            </>
          )
        }}
      </Formik>

    </div>
  );
}

export default UltimeModifiche;
