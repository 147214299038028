
import { Displayable } from 'django-rest-react';

export const BOZZAPROBLEMA_FONTE: Displayable[] = [
  { id: '2liv', display: 'Secondo livello' }, 
  { id: 'sen', display: 'Vecchio Senigallia' }, 
  { id: 'ipho', display: 'Vecchio IPhO' }, 
  { id: 'apho', display: 'Vecchia APhO' }, 
  { id: 'libro', display: 'Libro di testo (specificare)' }, 
  { id: 'orig', display: 'Originale, inventato da te da zero' }, 
  { id: 'altro', display: 'Altro' }, 
];

export const CATEGORIAEVENTO_TIPOLOGIE: Displayable[] = [
  { id: 'g', display: 'Gara a squadre' }, 
  { id: 's', display: 'Stage' }, 
];

export const FLAGSMISTATORE_FLAGS: Displayable[] = [
  { id: 'b', display: 'Buono' }, 
  { id: 'r', display: 'Da rivedere' }, 
  { id: 's', display: 'Scartato' }, 
  { id: 'd', display: 'Duplicato' }, 
];

export const PARTECIPANTE_PERMESSI: Displayable[] = [
  { id: 'a', display: 'Admin' }, 
  { id: 'm', display: 'Moderatore' }, 
  { id: 's', display: 'Smistatore' }, 
  { id: 'e', display: 'Evento' }, 
  { id: 'b', display: 'Base' }, 
  { id: 'x', display: 'Esterno' }, 
];

export const PROBLEMA_FONTE: Displayable[] = [
  { id: '2liv', display: 'Secondo livello' }, 
  { id: 'sen', display: 'Vecchio Senigallia' }, 
  { id: 'ipho', display: 'Vecchio IPhO' }, 
  { id: 'apho', display: 'Vecchia APhO' }, 
  { id: 'libro', display: 'Libro di testo (specificare)' }, 
  { id: 'orig', display: 'Originale, inventato da te da zero' }, 
  { id: 'altro', display: 'Altro' }, 
];

export const THREAD_TIPOLOGIE: Displayable[] = [
  { id: 'd', display: 'Discussione generica' }, 
  { id: 'p', display: 'Problema' }, 
  { id: 's', display: 'Shortlist' }, 
];

export const VERSIONESHORTLIST_STATO_SHORTLIST: Displayable[] = [
  { id: 'a', display: 'Approvata' }, 
  { id: 'p', display: 'Problemi da validare' }, 
  { id: 'r', display: 'Richiede modifiche' }, 
];