import React from 'react';
// @ts-ignore
import { parse, HtmlGenerator } from 'latex.js'

import { parse_latex } from 'django-rest-react'


const LaTeX = (props: { text: string }) => {
  const parsed_text = parse_latex(props.text);

  const tex_content = `
 \\documentclass[11pt,a4paper]{article}
 \\usepackage{siunitx}
 \\usepackage{amsmath}
 \\begin{document}
 \\vspace{1cm}
 ${parsed_text}
 \\end{document}
  `

  let generator = new HtmlGenerator({ hyphenate: false })
  try {
    const doc = parse(tex_content, { generator: generator }).htmlDocument(`${window.location.origin}/static/latex.js/`)
    return (
      <div style={{ maxWidth: "100%", maxHeight: "100%", overflow: "auto", overflowX: "hidden" }}>
        <iframe
          className="latex-frame"
          width="100%" height="100%"
          style={{
            minHeight: "250px", minWidth: "700px",
            maxHeight: "100%", maxWidth: "100%",
            overflow: "auto",
          }}
          srcDoc={doc.documentElement.outerHTML}
        />
      </div>
    )
  } catch (e) {
    return <div>{JSON.stringify(e)}</div>
  }
}

export default LaTeX
