import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { ThreadLista } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante } from '../reducers';


const ThreadFeed = (props: { thread: ThreadLista }) => {
  const partecipanti = usePartecipante(props)
  const { thread } = props;
  const p = partecipanti.find(item => item.id == thread.autore);
  if (!p) {
    return <LoadingCircle />
  }
  return (
    <>
      <span className="my-2">
        <i className="fa fa-plus-circle mx-2" />
        {nice_date(thread.data_inizio)} {displayPartecipanteBadge(p)}
        ha creato il thread <Link to={`/thread/problema/${thread.id}/`}>
          {thread.titolo}</Link>
      </span>
      <br />
    </>
  );
}

export default ThreadFeed;
