import React, { Component } from 'react'

import { image_placeholder_url } from '../globals'
import { Contributor } from '../api/types'


interface ownProps extends Contributor {
  class_name: string,
  size: number
}

type IProps = ownProps;


const foto_rotonda_style = (size: number) => {
  return {
    margin: '0 auto',
    marginBottom: '0.75em',
    width: `${size}vw`,
    height: `${size}vw`,
    borderRadius: `${size / 2}vw`,
  }
}



class FotoContributor extends Component<IProps> {

  render() {
    let { website, avatar_serio, full_name, user, class_name,
      size, punteggio_contributor } = this.props;
    let affiliation = "No affiliation";
    if (user.groups.length != 0) {
      affiliation = user.groups[0].name
    }
    let affiliation_banner = <p className="text-muted">{affiliation}</p>
    const real_image_src = avatar_serio ? `/avatars-seri/${avatar_serio}/` : image_placeholder_url;
    return (
      <div className="bannerino flex-grow-1">
        <a href={website} className={class_name} style={foto_rotonda_style(size)}>
          <img
            src={real_image_src}
            className="img-thumbnail"
            alt={full_name}
            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: `${size / 2}vw` }}
          />
        </a>
        <h4 className="text-row text-center">
          <a href={website}>
            {full_name}
          </a>
        </h4>
        {affiliation_banner}
        <h5 className="text-belize-hole">{punteggio_contributor.toFixed(2)}</h5>
      </div>
    )
  }
}


export default FotoContributor
