import React, { useRef, useState } from 'react';
import { Popover, Overlay, Button } from 'react-bootstrap'

import FormFlagSmistatore from '../components/FormFlagSmistatore';
import { ThreadDettaglio, ThreadLista } from '../api/types';

interface IProps {
  thread: ThreadDettaglio,
  thread_list: ThreadLista[]
}


export default function BottoneFlagSmistatore(props: IProps) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>
      <Button
        className={`btn btn-danger mr-3`} title="Flag da smistatore" onClick={() => setShow(!show)}
        ref={target}>
        <i className="fa fa-flag" />
      </Button>
      <Overlay
        placement="top"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => {
          return (
            <Popover id={`conferma`} {...old}>
              <Popover.Title>
                <span className="mr-2">
                  <i className="fa fa-times text-muted" onClick={() => setShow(false)} />
                </span>
                Imposta flags da smistatore
              </Popover.Title>
              <Popover.Content>
                <FormFlagSmistatore
                  thread={props.thread}
                  thread_list={props.thread_list}
                />
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
}
