import { PERFORM_LOGIN } from '../actions/action-types';
import { UserWhoami } from '../api/types'
import { performLoginAction } from '../actions';


export interface LoginState {
  logged_in: boolean,
  user: UserWhoami
}


export function loginReducer(state: LoginState = {
  logged_in: false,
  user: {
    id: -1,
    user: {
      username: "unknown",
      first_name: "Anonimo",
      last_name: "Anonimo",
      is_superuser: false,
      is_staff: false,
    },
    attivo: false,
    problemi_corretti: [],
    problemi_proposti: [],
    shortlists_visibili: [],
    permessi: 'b',
    ricevi_email: false,
    website: "",
    avatar: -1,
    avatar_serio: -1,
    categorie_preferite: [],
    thread_letti: [],
    eventi_assegnati: [],
  }
},
  action: performLoginAction) {
  if (action.type == PERFORM_LOGIN) {
    return Object.assign({}, state, {
      logged_in: true,
      user: action.user
    });
  } else {
    return state;
  }
}
