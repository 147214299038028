import React, { Component } from 'react';
import { Link } from "react-router-dom";


interface IProps {
  url: string
  color: string
  text: string
  icon: string
  size: string
  iconSize?: string
  margin?: number
  hardlink?: boolean
}

export default class Tile extends Component<IProps> {

  render() {
    const size = this.props.size || "tile-medium";
    const margin = (this.props.margin || this.props.margin === 0) ? this.props.margin : 3;
    const pad = (margin > 0) ? `mx-${margin} my-${margin}` : null;
    const classes = `tile ${pad} bg-${this.props.color} ${size}`;
    const iconSize = this.props.iconSize || "icon";
    const icon = `fa ${iconSize} ${this.props.icon}`;
    const hardlink = this.props.hardlink || false;
    const content = (
      <div className={classes} title={this.props.text}>
        <span className="branding-bar">{this.props.text}</span>
        <i className={icon}></i>
      </div>
    );
    if (hardlink) {
      return (
        <a href={this.props.url}>
          {content}
        </a>
      );
    } else {
      return (
        <Link to={this.props.url}>
          {content}
        </Link>
      );
    }
  }
}
