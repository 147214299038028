import React, { useEffect } from 'react';

import { Tile } from '../components';
import { canSeeShortlist, login_url } from '../globals'
import { useLogin } from '../reducers'


const Home = () => {
  const login = useLogin();
  useEffect(() => {
    document.title = `Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  let shortlist;
  if (canSeeShortlist(login.user)) {
    shortlist = <div className="mt-2">
      <h2>Shortlists</h2>
      <div className="d-flex flex-wrap align-content-between flex-fill">
        <Tile
          url={`/shortlist/`}
          text="Shortlists"
          color="wet-asphalt" icon="fa fa-list"
          size="tile-wide"
        />
      </div>
    </div>
      ;
  }
  return (
    <div className="container">
      <h1 className="page-header">Owlitrack homepage</h1>
      <h2>Thread per i problemi</h2>
      <div className="d-flex flex-wrap align-content-between flex-fill">
        <Tile
          url={`/thread/problema/miei/`}
          text="I problemi proposti da me"
          color="secondary" icon="fa fa-piggy-bank"
          size="tile-wide"
        />
        <Tile
          url={`/thread/problema/assegnati/`}
          text="I problemi che mi hanno assegnato"
          color="primary" icon="fa fa-clipboard-list"
          size="tile-wide"
        />
        <Tile
          url={`/thread/problema/`}
          text="Tutti i problemi"
          color="teal" icon="fa fa-list-ul"
          size="tile-wide"
        />
        <Tile
          url={`/thread/problema/new/`}
          text="Proponi un problema"
          color="success" icon="fa fa-plus"
          size="tile-wide"
        />
        <Tile
          url={`/utente/`}
          text="Tutti gli utenti"
          color="amethys" icon="fa fa-address-book"
          size="tile-wide"
        />
        <Tile
          url={`/last-news/`}
          text="Ultimi avvenimenti"
          color="pumpkin" icon="fa fa-newspaper"
          size="tile-wide"
        />
        <Tile
          url={`/drafts/`}
          text="Bozze"
          color="info" icon="fa fa-cogs"
          size="tile-wide"
        />
        <Tile
          url="/top-contributors/"
          text="Top contributors"
          color="sunflower" icon="fa fa-trophy"
          size="tile-wide"
        />
      </div>
      {shortlist}
    </div>
  )
}

export default Home;
