import React from 'react';
import { Link } from 'react-router-dom'


import { displayEvento } from '../utils'
import { ThreadLista } from '../api/types'
import CategoriaMap from './CategoriaMap'
import { correzioni_richieste, canSeeSoluzioneSpiegata } from '../globals';
import { useLogin } from '../reducers';


const ShortlistDettaglio = (props: { item: ThreadLista }) => {
  const login = useLogin();
  const { item } = props;
  const extra_props = item.letto ?
    { className: "text-secondary" } :
    { className: "text-primary" };
  const extra_icon = item.problema.evento_assegnato ?
    <i className="mr-1 fa fa-times" title={`Assegnato nell'evento ${displayEvento(item.problema.evento_assegnato)}`} /> :
    null;
  let check_color = "mr-1 fa fa-check text-";
  if (item.problema.correttori_eseguito.length == 0) {
    check_color += "danger";
  } else if (item.problema.correttori_eseguito.length < correzioni_richieste) {
    check_color += "warning";
  } else {
    check_color += "success";
  }

  let soluzione_color = "danger";
  if (!!item.problema.soluzione_spiegata && item.problema.soluzione_spiegata != "") {
    soluzione_color = "success";
  }

  return (
    <>
      <Link to={`/thread/problema/${item.id}/`}>
        <h6 {...extra_props}>{extra_icon}
          <span className={check_color} />
          {canSeeSoluzioneSpiegata(login.user) && <span className={`mr-1 fa fa-thumbs-up text-${soluzione_color}`} title={"Presenza soluzione spiegata"} />}
          #{item.id} - {item.titolo}</h6>
      </Link>
      <CategoriaMap categorie={item.problema.categoria} />
    </>
  );
}


export default ShortlistDettaglio;
