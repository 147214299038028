import React, { Component } from 'react';
import { VERSION } from '../globals';


class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <>
        <footer className="mt-5 py-2 bg-primary justify-content-center text-center" id="footer">
          <div>
            <small className="container text-center text-white">
              Owlitrack {VERSION}
              <br />
              Copyright &copy; 2019-{year} Fabio Zoratti
            </small>
          </div>
        </footer>
      </>
    );
  }
}


export default Footer;
