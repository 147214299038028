import React, { useState } from 'react'
import { diffWords } from 'diff'

import { useFormikContext, getIn } from 'formik'
import { Linter } from '../api/types'

interface ownProps {
  suggerimento: Linter,
  fieldname: string,
}



const LinterSuggerimento = (props: ownProps) => {
  const { fieldname, suggerimento } = props;
  const { values, setFieldValue } = useFormikContext();
  const [hide, setHide] = useState(false);

  if (hide) {
    return <></>
  }
  const original = getIn(values, fieldname)
  const hasrepl = !!suggerimento.replacement;
  let diffed;
  if (hasrepl) {
    const diff = diffWords(suggerimento.original, suggerimento.replacement);
    diffed = diff.map((part, idx) => {
      const color = part.added ? 'success' :
        part.removed ? 'danger' : null;
      return color ? <span className={`text-${color}`} key={idx}>{part.value}</span> : part.value
    })
  }
  return (
    <div className="card px-2 py-2">
      <span>{suggerimento.description}</span>
      {!hasrepl &&
        <p>Il testo incriminato è {suggerimento.original}</p>
      }
      {hasrepl &&
        <>
          <h6>Vuoi effettuare il replacement?</h6>
          <p>{diffed}</p>
          <button className="btn btn-info" type="button" onClick={() => {
            setFieldValue(fieldname, original.replaceAll(suggerimento.original, suggerimento.replacement));
            setHide(true);
          }}>Effettua sostituzione</button>
        </>
      }
    </div>
  )
}


export default LinterSuggerimento
