import React from 'react';
import { Link } from 'react-router-dom'

import { displayCategoria, displayCategoriaColore } from '../utils';
import { useCategoria } from '../reducers';


interface ownProps {
  categorie: number[]
}


const CategoriaMap = (props: ownProps) => {
  useCategoria(props);
  const { categorie } = props;
  return (
    <>
      {categorie.map(item => {
        return (
          <Link to={`/thread/problema/categoria/${item}/`} key={item}>
            <span className={`badge py-1 px-1 mx-1 badge-${displayCategoriaColore(item)}`}>{displayCategoria(item)}</span>
          </Link>
        )
      })}
    </>
  );
}


export default CategoriaMap;
