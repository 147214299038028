import React, { useState } from 'react';
import { useFormikContext, getIn } from 'formik';


import MdMath from '../components/MdMath';

interface IProps {
  fieldname: string,
}


const Anteprima = (props: IProps) => {

  const [hideSpoiler, setHideSpoiler] = useState(false);
  const { values } = useFormikContext();
  const text = getIn(values, props.fieldname);

  return (
    <div className="card my-1">
      <div className="card-body">
        <h5 className="card-title">Anteprima</h5>
        <small>MathJaX è attivo. Digita nel form sottostante e vedrai in anteprima i
          messaggi con le formule renderizzate se inserite fra tag <code className="text-danger">
            $ $</code> (inline)
          o <code className="text-danger">$$ $$</code>. Puoi anche inserire emoticon
          se conosci il loro valore inserendole fra due punti, come <code>:smile:</code>.
          È abilitata la sintassi <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">MarkDown</a>.
          È abilitata la funzione spoiler.
          Inserisci testo fra <code>[spoiler]testo[/spoiler]</code> e sarà necessario cliccarci per leggerlo.
          Puoi inoltre citare persone (@nome.cognome), citare problemi (#numero), citare eventi (!numero). Vedrai il link in anteprima.
        </small>
        <br />
        <button type="button" className="btn btn-info" onClick={() => setHideSpoiler(!hideSpoiler)}>Toggle spoiler</button>
        <br />
        <br />
        <MdMath source={text} hideSpoiler={hideSpoiler} />
      </div>
    </div >
  );
}

export default Anteprima
