export type {
  User, Evento, Allegato, BozzaProblema,
  Problema, VersioneShortlist, Shortlist, ThreadShortlist,
  FlagSmistatore, Partecipante, Commento, Categoria,
  ProblemaVersionatoFeed,
  ThreadProblemGenericConRisposta as ThreadLista,
  ThreadProblemDetailConRisposta as ThreadDettaglio,
  RispostaConRisposta as Risposta,
  SoluzioneConRisposta as Soluzione,
  CommentoVersionatoFeed as CommentoVersioneFeed,
  CommentoVersionato as CommentoVersione,
  VotoBellezza as Voto,
  AssegnazioneProblema as Assegnazione,
  AssegnazioneProblemaEvento as AssegnazioneEvento,
  Whoami as UserWhoami,
  ProblemaVersionato as ProblemaVersione,
  PartecipanteContributor as Contributor, CitazioneThread,
  Linter, CategoriaEvento, BozzaProblemaForm,
  ThreadProblemGenericForm, ThreadProblemGenericConRisposta,
  BuildShortlistForm,
} from './auto-types'
export { WithId } from 'django-rest-react'
import {
  Problema, Partecipante, FlagSmistatore, VersioneShortlist, SoluzioneConRispostaSenzaProblema as Soluzione,
  BozzaProblemaForm, ThreadProblemGenericConRispostaForm,
} from './auto-types'
import { plain_object } from 'django-rest-react';


type SoluzioneWrite = "label" | "udm" | "analitica" | "soluzione" | "soluzione_exp" | "precisione"
export const SOLUZIONE_KEYS: SoluzioneWrite[] = [
  "label", "soluzione", "soluzione_exp", "udm", "analitica", "precisione",
]

export type Permessi = Partecipante["permessi"]
export type FlagSmistatoreChar = FlagSmistatore["flag"]
export type StatoShortlist = VersioneShortlist["stato"]
export type Fonte = Problema["fonte"]
export type SoluzioneData = Omit<Soluzione, "id">
export type PatchSoluzioneData = Partial<SoluzioneData>;
export type BozzaProblemaData = BozzaProblemaForm;
export type ProblemaData = Omit<ThreadProblemGenericConRispostaForm, "autore">;



export interface FormValuesAllegato extends plain_object {
  titolo: string,
  _file: File,
  scelta?: number,
}


export interface FiltroVoto extends plain_object {
  partecipante?: number,
  problema?: number,
}

export interface FiltroProblemaThread extends plain_object {
  ass?: 'y',
  miei?: 'y',
  cat?: number
}


export interface NewShortlistData extends plain_object {
  shortlist: {
    titolo: string,
    parent?: number,
    evento?: number,
    target_numero_problemi: number
  }
}



export interface CommentoData extends plain_object {
  thread: number,
  versioni: {
    testo: string,
    allegati: number[]
  }[],
}


export interface ProfileData extends plain_object {
  categorie_preferite: number[]
}


export interface VersioneShortlistForm extends plain_object {

}


export interface FlagsData extends plain_object {
  problema: number,
  messaggio_extra: string,
  flag: string,
  problemi_simili: number[]
}
