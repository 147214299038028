import React from 'react'
import {
  get_commento_list, get_risposte,
  get_feed_modifiche_commenti, get_feed_modifiche_problemi, get_feed_assegnazione,
  get_feed_assegnazione_evento,
  get_commenti_shortlist,
  get_citazioni_thread
} from "../api"

import {
  CommentoFeed, RispostaFeed, ThreadFeed, ModificaProblemaFeed,
  CommentoVersioneFeed, AssegnazioneFeed, AssegnazioneEventoFeed, Commento, CitazioneThreadFeed
} from '../components';
import { useThread } from '../reducers';


interface FeedFilter {
  data__lte?: string,
  data__gte?: string,
  problema?: number,
  commento__thread?: number,
  thread?: number,
  thread__pk?: number,
  partecipante?: number,
  autore?: number,
}


interface Filter {
  commenti: FeedFilter,
  commenti_shortlist: FeedFilter,
  risposte: FeedFilter,
  assegnazione: FeedFilter,
  modifiche_commenti: FeedFilter,
  modifiche_problemi: FeedFilter,
  citazioni: FeedFilter,
}

type ProbArr = ReturnType<typeof useThread>


export const emptyFeed: Awaited<ReturnType<typeof fetchFeed>> = {
  commenti: [],
  risposte: [],
  assegnazione_problemi: [],
  assegnazione_evento: [],
  modifiche_problemi: [],
  modifiche_comm: [],
  citazioni_thread: [],
}

export const fetchFeed = async (filtro: Filter) => {
  const commenti = get_commento_list(filtro.commenti)
  const commenti_shortlist = get_commenti_shortlist(filtro.commenti)
  const risposte = get_risposte(filtro.risposte)
  const assegnazione_prob = get_feed_assegnazione(filtro.assegnazione)
  const assegnazione_evento = get_feed_assegnazione_evento(filtro.assegnazione)
  const modifiche_prob = get_feed_modifiche_problemi(filtro.modifiche_problemi)
  const modifiche_comm = get_feed_modifiche_commenti(filtro.modifiche_commenti)
  const citazioni_thread = get_citazioni_thread(filtro.citazioni)

  return {
    commenti: (await commenti).concat(await commenti_shortlist),
    risposte: await risposte,
    assegnazione_problemi: await assegnazione_prob,
    assegnazione_evento: await assegnazione_evento,
    modifiche_problemi: await modifiche_prob,
    modifiche_comm: await modifiche_comm,
    citazioni_thread: await citazioni_thread,
  }
}

export const renderFeed = (
  feed: Awaited<ReturnType<typeof fetchFeed>>,
  problemi: ProbArr,
  filterProb: (prob: ProbArr[0]) => boolean,
  detail_commento: boolean,
  show_link: boolean) => {


  const CommentoComponent = detail_commento ? Commento : CommentoFeed

  // Filtra via commenti shortlist
  const feed_comm = feed.commenti.map(item => {
    return {
      date: item.data, content: <CommentoComponent commento={item} />
    };
  });
  const feed_risp = feed.risposte.map(item => {
    return {
      date: item.data,
      content: <RispostaFeed risposta={item} link={show_link} />
    };
  });
  const feed_thread = problemi.filter(filterProb)
    .map(item => {
      return {
        date: item.data_inizio,
        content: <ThreadFeed thread={item} />
      }
    })
  const feed_mod_comm = feed.modifiche_comm.filter(item => (item.num != 0))
    .map(item => {
      return {
        date: item.data,
        content: <CommentoVersioneFeed commento_versione={item} problemi={problemi} />
      };
    });
  const feed_mod_problemi = feed.modifiche_problemi.filter(item => (item.num != 0))
    .map(item => {
      return {
        date: item.data,
        content: <ModificaProblemaFeed versione={item} link={show_link} problemi={problemi} />
      }
    })
  const feed_assegnazioni = feed.assegnazione_problemi.map(item => {
    return {
      date: item.data,
      content: <AssegnazioneFeed assegnazione={item} />
    }
  });
  const feed_assegnazioni_evento = feed.assegnazione_evento.map(item => {
    return {
      date: item.data,
      content: <AssegnazioneEventoFeed assegnazione={item} />
    }
  });
  const feed_citazioni_thread = feed.citazioni_thread.map(item => {
    return {
      date: item.data,
      content: <CitazioneThreadFeed citazione={item} />
    }
  })

  return feed_comm
    .concat(feed_risp)
    .concat(feed_thread)
    .concat(feed_mod_comm)
    .concat(feed_mod_problemi)
    .concat(feed_assegnazioni)
    .concat(feed_assegnazioni_evento)
    .concat(feed_citazioni_thread)
    .sort((a, b) => {
      return (new Date(a.date)).getTime() - (new Date(b.date)).getTime();
    }).map((item, idx) => <React.Fragment key={idx}>{item.content}</React.Fragment>);
}
