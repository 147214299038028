import React, { Component } from 'react';


export default class LoginError extends Component {

  render() {
    return (
      <div className="container">
        <h1 className="page-header">Errore di login</h1>
        <p>Se sei qui dopo un tentativo di login, vuol dire che il tuo indirizzo email non è autorizzato a fare login qui. Contatta un amministratore per farti dare il permesso.</p>
      </div>
    );
  }
}
