import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { Assegnazione } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante, useThread } from '../reducers';


interface ownProps {
  assegnazione: Assegnazione
}

const AssegnazioneFeed = (props: ownProps) => {
  const { assegnazione } = props;
  const partecipanti = usePartecipante(props);
  const problemi = useThread(props);


  const p = problemi.find(i => (i.id == assegnazione.thread));
  let titolo = "";
  if (p) {
    titolo = p.titolo;
  }
  const part1 = partecipanti.find(i => i.id == assegnazione.assegnatore);
  const part2 = partecipanti.find(i => i.id == assegnazione.assegnato);
  if (!part1 || !part2) {
    return <LoadingCircle />
  }
  const testo_1 = assegnazione.aggiunto ? "ha assegnato il problema" :
    "ha tolto il problema";
  const testo_2 = assegnazione.aggiunto ? "a" : "da";
  const badge = assegnazione.aggiunto ? "fa-hand-point-right" : "fa-hand-point-left";
  return (
    <>
      <span className="my-2">
        <i className={`fa ${badge} mx-2`} />
        {nice_date(assegnazione.data)} {displayPartecipanteBadge(part1)}
        {testo_1}
        <span className="mx-1">
          <Link to={`/thread/problema/${assegnazione.thread}/`}>
            {titolo}
          </Link>
        </span>
        {testo_2} {displayPartecipanteBadge(part2)}
      </span>
      <br />
    </>
  );
}

export default AssegnazioneFeed;
