import React from 'react';
import { Link } from "react-router-dom";

import store from '../store';
import { add_toast_action } from '../actions';
import { ThreadLista } from '../api/types'
import { sentry_log } from '../utils'


interface CommonMessage {
  payload: any,
  type: string
}

interface WorkerEvent extends Event {
  data: string
}

interface ProblemiMessage extends CommonMessage {
  payload: ThreadLista[]
}



const handle_problemi_non_votati = (message: ProblemiMessage) => {
  store.dispatch(add_toast_action({
    title: "Problema non votato",
    message: <>
      <p>Non hai ancora votato dei problemi con cui hai interagito: </p>
      <ul>
        {
          message.payload.map(item => {
            return <li key={item.id}>
              <Link to={`/thread/problema/${item.id}/`} key={item.id} >
                {item.titolo}
              </Link>
            </li>;
          })
        }
      </ul>
    </>,
    time: new Date(),
  }));
}

const handle_categorie_preferite_non_inserite = (message: CommonMessage) => {
  store.dispatch(add_toast_action({
    title: "Categorie preferite non inserite.",
    message: <>
      Non hai ancora inserito le categorie preferite.
      Vai sulla pagina del<Link to={`/accounts/profile/`}> profilo </Link> per inserirle.
    </>,
    time: new Date(),
  }));
}


export const dedicated_worker_handler = (event: WorkerEvent) => {
  const message = JSON.parse(event.data) as CommonMessage;
  if (message.type == "problemi_non_votati") {
    handle_problemi_non_votati(message);
  } else if (message.type == "categorie_preferite_non_inserite") {
    handle_categorie_preferite_non_inserite(message);
  } else {
    sentry_log(new Error(`Unknown message type from dedicated worker: ${message}`));
  }
}


export const dedicated_worker_error_handler = (e: ErrorEvent) => {
  // @ts-ignore
  sentry_log(e);
}
