import React from 'react';
import { Link } from 'react-router-dom'

import { useCategoriaEvento } from '../reducers';

interface ownProps {
  cats: number[],
}

const EventoMap = (props: ownProps) => {
  const categoriaevento = useCategoriaEvento(props);
  const { cats } = props;
  return (
    <>
      {categoriaevento.filter(cat => cats.includes(cat.id)).map(item => {
        return <Link to={`/thread/problema/categoria-evento/${item.id}/`} key={item.id}>
          <span className="ml-1">{item.nome}</span><br />
        </Link>
      })}
    </>
  );
}


export default EventoMap;
