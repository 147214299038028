import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { mapStateToProps, LoginStateProps } from '../utils';
import { GOOGLE_URL } from '../globals'


class NavbarNC extends React.Component<LoginStateProps> {

  render() {
    let login_block;
    const { user } = this.props.login.user;
    if (this.props.login.logged_in) {
      login_block = (
        <>
          <Link className="dropdown-item" to={`/accounts/profile/`}>Il tuo profilo</Link>
          <a className="dropdown-item" href="/accounts/logout/">Logout</a>
          {(user.is_staff || user.is_superuser) &&
            <a className="dropdown-item" href="/admin/">Django admin</a>
          }
        </>
      );
    } else {
      login_block = (
        <>
          <a className="dropdown-item" href={GOOGLE_URL}>Login con Google</a>
        </>
      );
    }
    const data = this.props.login.user.user;
    let log_name = this.props.login.logged_in ? data.first_name + " " + data.last_name : "Login";
    return (
      <nav className="navbar navbar-nav navbar-dark bg-primary navbar-expand-lg px-3">
        <div className="container">
          <Link to={`/`} className="navbar-brand">
            <img src={`/static/favicon.ico`}
              alt="Buona Fortuna." width="33"
              className="mx-1"
            />
            Owlitrack
          </Link>
          <button
            className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={`/about/`} className="nav-link">Guida</Link>
              </li>
              <li className="nav-item">
                <Link to="/guidelines/" className="nav-link">Stile</Link>
              </li>
              <li className="nav-item">
                <Link to={`/costanti/`} className="nav-link">Costanti numeriche</Link>
              </li>
              <li className="nav-item">
                <Link to={`/changelog/`} className="nav-link">Changelog</Link>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item dropdown ml-auto">
                <a className="nav-link dropdown-toggle" href="#"
                  id="navbarDropdownMenuLink" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  {log_name}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {login_block}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const Navbar = connect(mapStateToProps)(NavbarNC);
export default Navbar;
