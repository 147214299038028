import React, { Component, ReactElement, forwardRef, useState } from 'react';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import 'react-widgets/styles.css';
import DateTimePicker from 'react-widgets/DatePicker'
import Localization from 'react-widgets/Localization'
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import { FieldProps, useFormikContext, FormikContextType } from 'formik';

import { WithId, formNameToNice, flattenKeys, Displayable } from 'django-rest-react';

interface IProps {
  name: string,
  placeholder?: string,
  onChange?: any,
  type: "select" | "text" | "email" | "password" | "checkbox" |
  "number" | "select-multiple" | "textarea" | "file" | "date" | "datetime",
  displayName?: string | ReactElement,
  help_text?: string | ReactElement,
  displayChoice?(item: WithId): string,
  timePrecision?: "minutes" | "seconds" | "milliseconds"
  choices?: WithId[],
  readOnly?: boolean,
  step?: number
  id?: string,
  search?: boolean,
  extra_append?: any,
  validate?: (val: any) => string,
}


interface TypeFieldProps extends FieldProps {
  type: string,
  search: boolean,
  set_search_string: (_: string) => void
  search_string: string,
  extra_append?: any
}


function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


const build_filter_button = (set_s: any, old_s: any) => {
  let popover = (
    <Popover title="Filtra le scelte" id="search-filter" style={{ maxWidth: '600px', padding: '10px' }}>
      <Formik onSubmit={(values) => set_s(values.search)} initialValues={{
        search: old_s
      }}>
        {({ handleSubmit }) => {
          return (
            <div className="container-fluid">
              <Form autoComplete="off" className="needs-validation">
                <FormGroupBootstrap
                  type="text" name="search" help_text="Case insensitive. Sono ammesse espressioni regolari js."
                />
                <button className="btn btn-info" type="button" onClick={(...props: any) => handleSubmit(...props)}>
                  <i className="fa fa-search" />
                </button>
              </Form>
            </div>
          )
        }}
      </Formik>
    </Popover>
  )
  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
      <div className="input-group-append">
        <div className="input-group-text">
          <i className="fa fa-search" />
        </div>
      </div>
    </OverlayTrigger>
  );
}



class CustomInputForm extends Component<TypeFieldProps> {
  render() {
    const {
      field, form: { errors },
      type, search, extra_append,
      set_search_string, search_string,
      ...props
    } = this.props;
    let classnames = "form-control";

    if (flattenKeys(errors)[field.name]) {
      classnames += " is-invalid";
    }
    const attrs = { type: type, ...field, ...props, className: classnames };
    let selector;
    const filter_button =
      ((type == "select" || type == "select-multiple") && search) ?
        build_filter_button(set_search_string, search_string) :
        null;
    // @ts-ignore
    const { children } = props;

    switch (type) {
      case "select":
        selector = (
          <select {...attrs}>
            {children}
          </select>
        ); break;
      case "select-multiple":
        selector = (
          <select {...attrs} multiple>
            {children}
          </select>
        ); break;
      case "textarea":
        selector = (
          <textarea {...attrs} style={{ height: '150px' }}>
            {children}
          </textarea>
        ); break;
      default:
        selector = (
          <input {...attrs}>
            {children}
          </input>
        );
    }
    return (
      <>
        {selector}
        {filter_button}
        {extra_append}
        <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
      </>
    );
  }
}



const get_extra_props = (props: IProps, set_search_string: (s: string) => void) => {
  let extra_props: Partial<IProps & TypeFieldProps> = {};
  const PROPS: (keyof IProps)[] = ["name", "placeholder", "onChange", "step", "id", "readOnly", "validate", "type"]
  for (const key of PROPS) {
    if (props.hasOwnProperty(key)) {
      // @ts-ignore
      extra_props[key] = props[key]
    }
  }
  if (props.search) {
    extra_props.search = props.search;
    extra_props.set_search_string = set_search_string
  }
  return extra_props
}

const FormGroupBootstrap = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const [search_string, set_search_string] = useState("");
  const { name } = props;
  const nice_name = props.displayName ? props.displayName : formNameToNice(name);
  const { values, setFieldValue, errors } = useFormikContext()
  let field; let children; let help_text;
  let extra_props = get_extra_props(props, set_search_string)
  if (props.type == "select" || props.type == "select-multiple") {
    let display = props.displayChoice ? props.displayChoice :
      (item: Displayable) => item.display;
    extra_props.search_string = search_string;
    const empty_option = props.type == "select" ? (<option></option>) : null;
    let filter_func = (item: Displayable) => true;
    if (props.search && search_string != "") {
      filter_func = (item) => {
        const reg = new RegExp(search_string, 'i');
        return (display(item).search(reg) != -1);
      }
    }

    children = (
      <>
        {empty_option}
        {props.choices.filter(filter_func).map((item, idx) => {
          return <option value={item.id} key={idx}>{display(item)}</option>;
        })}
      </>
    );
  }

  if (props.type == "file") {
    const extra_class = flattenKeys(errors)[name] ? "is-invalid" : "";
    const onChange = ((event: any) =>
      setFieldValue(name, event.currentTarget.files[0]))
    field = (
      <div className="d-flex flex-column">
        <input
          id={name} name={name} type="file" onChange={onChange}
          className={`form-control form-control-file ${extra_class}`}
        />
        <ErrorMessage name={name} component="small" className="text-danger text-small" />
      </div>
    );
  } else if (props.type == "date" || props.type == "datetime") {
    const time = props.type == "datetime"
    // @ts-ignore
    const timestamp = Date.parse(values[name]);
    const setvalue = props.type == "datetime" ?
      (value: Date) => setFieldValue(name, value.toISOString()) :
      (value: Date) => setFieldValue(name, formatDate(value));
    if (isNaN(timestamp)) {
      setvalue(new Date())
    }
    // @ts-ignore
    const val = new Date(values[name]);
    field = (
      <div className="d-flex flex-column">
        <Localization
          date={new DateLocalizer({ culture: 'it-IT' })}
        >
          <DateTimePicker
            value={val}
            onChange={setvalue}
            messages={{ dateButton: "Data" }}
            includeTime={time}
            timePrecision="seconds"
            valueDisplayFormat={{ dateTimeStyle: "medium" }}
          />
        </Localization>
        <div>
          <ErrorMessage name={name} component="small" className="text-danger text-small" />
        </div>
      </div>
    )
  } else {
    field = (
      <Field
        component={CustomInputForm}
        {...extra_props}
        extra_append={props.extra_append}
      >
        {children}
      </Field>
    );
  }
  if (props.help_text) {
    if (typeof props.help_text == "string") {
      help_text = <p className="form-text text-muted">{props.help_text}</p>;
    } else {
      help_text = props.help_text
    }
  }
  return (
    <div className="form-group row">
      <label
        className="col-sm-2 col-form-label" htmlFor={name}
      >
        {nice_name}
      </label>
      <div className="col-sm">
        <div className="input-group" ref={ref}>
          {field}
        </div>
        {help_text}
      </div>
    </div>
  );
})

export default FormGroupBootstrap;
