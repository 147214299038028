import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'

import { PermissionDenied } from 'django-rest-react'
import {
  displayEvento, displayPartecipante, nice_date,
} from '../utils'
import { canSeeShortlist, login_url } from '../globals'
import { useShortlist, useLogin, useEvento, usePartecipante, useThread } from '../reducers'

type IProps = any

const ListaShortlist = (props: IProps) => {
  const login = useLogin();
  useEffect(() => {
    document.title = `Shortlists - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  if (!canSeeShortlist(login.user)) {
    throw new PermissionDenied({
      message: "Non hai abbastanza permessi per vedere le shortlist."
    })
  }

  const shortlist = useShortlist(props);
  const eventi = useEvento(props);
  const partecipanti = usePartecipante(props)
  const thread = useThread(props);

  return (
    <div className="container">
      <h1 className="page-header">Lista delle shortlist</h1>
      <div className="table-responsive my-3">
        <table className="table table-bordered table-hover table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Titolo</th>
              <th>Evento</th>
              <th>Autore</th>
              <th>Ultima modifica</th>
              <th>Build</th>
            </tr>
          </thead>
          <tbody>
            {shortlist.map(item => {
              const ultimo = item.shortlist.versioni[item.shortlist.versioni.length - 1];
              return (
                <tr key={item.id}>
                  <td>
                    <Link to={`/shortlist/${item.id}/`}>
                      {item.titolo}
                    </Link>
                  </td>
                  <td>{item.shortlist.evento &&
                    <Link to={`/thread/problema/evento/${item.shortlist.evento}/`}>
                      {displayEvento(item.shortlist.evento)}
                    </Link>}
                  </td>
                  <td>
                    <Link to={`/utente/${item.autore}/`}>
                      {displayPartecipante(item.autore)}
                      <br />
                      {nice_date(item.data_inizio)}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/utente/${ultimo.ultimo_revisore}/`}>
                      {displayPartecipante(ultimo.ultimo_revisore)}
                      <br />
                      {nice_date(ultimo.data)}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/shortlist/build/${item.id}/testo/`} className="btn btn-success" title="TeX testo"><span className="fa fa-hammer" /> </Link>
                    <Link to={`/shortlist/build/${item.id}/soluzioni/`} className="btn btn-info" title="TeX soluzioni"><span className="fa fa-hammer" /> </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Link to="/shortlist/new/">
        <button className="btn btn-success">
          <i className="fa fa-plus" /> Nuova shortlist
        </button>
      </Link>
    </div>
  )
}


export default ListaShortlist;
