import React from 'react'
import { useFormikContext } from 'formik'
import { cloneDeep } from 'lodash'

import { FormGroupBootstrap, MdMath } from '../components'
import { DECIMAL_PLACES } from '../globals'
import { ProblemaData as FormValues } from '../api/types'


const build_name = (idx: number, name: string) => `problema.soluzioni[${idx}].${name}`

const MultipleSoluzioniForm = () => {
  const formik = useFormikContext();
  const { setFieldValue } = formik;
  const values = formik.values as FormValues;
  // Questo accade sulla pagina delle bozze
  if (!values.problema) {
    setFieldValue("problema", { soluzioni: [] })
    return <></>
  }
  const count = !!values.problema.soluzioni ? values.problema.soluzioni.length : 0
  return (
    <>
      <div className="d-flex col-sm py-2">
        <button type="button" onClick={() => {
          setFieldValue(`problema.soluzioni[${count}]`, {
            label: `Domanda ${count + 1}`,
            udm: "$\\SI{}{}$",
            analitica: "",
            soluzione: undefined,
            soluzione_exp: undefined,
            precisione: 0.005,
          })
        }}
          className="mr-auto btn btn-success">Aggiungi una risposta</button>
      </div>
      {count >= 0 &&
        Array.from(Array(count).keys()).map(idx =>
          <div className="card my-2" key={idx}>
            <div className="card-body">
              <button type="button" onClick={() => {
                const arrcopy = cloneDeep(values.problema.soluzioni);
                arrcopy.splice(idx, 1)
                setFieldValue("problema.soluzioni", arrcopy)
              }}
                className="ml-auto btn btn-danger my-2"
              >
                <i className="fa fa-times" />
              </button>

              <FormGroupBootstrap
                name={build_name(idx, "label")}
                help_text="Un indicatore che indicherai nel testo"
                type="text"
              />
              <FormGroupBootstrap
                name={build_name(idx, "soluzione")} displayName="Valore"
                type="number" step={DECIMAL_PLACES}
                help_text={"Soluzione numerica. L'unità di misura è nel campo sotto. "
                  + "Indica la risposta in notazione scientifica con un alto "
                  + "numero di cifre significative. C'è il campo sotto per l'esponente."}
              />
              <FormGroupBootstrap
                name={build_name(idx, "soluzione_exp")} displayName="Esponente valore"
                type="number" step={1}
              />

              <FormGroupBootstrap
                name={build_name(idx, "precisione")} displayName="Precisione"
                type="number" step={DECIMAL_PLACES}
                help_text={"La precisione con cui viene controllata la risposta. (1/(1 + p) <= r/a <= 1 + p)"}
              />
              <FormGroupBootstrap
                name={build_name(idx, "udm")} displayName="Unità di misura"
                help_text={"Le unitá di misura del risultato. Dare con MathJax e.g. $\\SI{}{m/s}$." +
                  "Usare \\SI{}{} se adimensionale. Cercare di dare una risposta in unità più basiche " +
                  "possibili, e.g. m e non km, per non 'suggerire' la risposta"}
                type="text"
                extra_append={
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <MdMath source={values.problema.soluzioni[idx].udm || ""} />
                    </div>
                  </div>
                }
              />
              <FormGroupBootstrap
                name={build_name(idx, "analitica")} displayName="Soluzione analitica"
                type="text"
                help_text="Se applicabile, indicare la soluzione analitica a questo punto del problema, in formule. Utilizzare il LaTeX includendo fra dollari il testo."
              />
              <div className="text-center">
                <MdMath source={values.problema.soluzioni[idx].analitica || ""} />
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}


export default MultipleSoluzioniForm
