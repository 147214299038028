import {
  PERFORM_LOGIN, CREATE_REGISTRATION
} from './action-types';
import { UserWhoami } from '../api/types';
import { RegistrationType } from '../reducers/webpush'
export {
  ADD_LOADING, DEL_LOADING, RESET_TENTATIVO, NUOVO_TENTATIVO,
  del_loading, add_loading, add_tentativo_action, reset_tentativo_action,
  loadingAction, TentativoAction,
} from 'django-rest-react'
export * from './toast'


export interface performLoginAction {
  type: typeof PERFORM_LOGIN,
  user: UserWhoami
}

export function set_user_data(user: UserWhoami): performLoginAction {
  return { type: PERFORM_LOGIN, user: user };
}


export function create_registration(reg: RegistrationType) {
  return {
    type: CREATE_REGISTRATION,
    registration: reg
  }
}
