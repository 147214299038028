import React, { useEffect, useState } from 'react'
import { build_tex_problema } from '../api';
import LoadingCircle from './LoadingCircle';
import './RealTeXLoad.scss';


type CallFunc = () => ReturnType<typeof build_tex_problema>;


const RealTeXLoad = (props: { api: CallFunc, callback: () => void, force_refresh?: number }) => {
  const [pdf, setPdf] = useState(null);
  const [log, setLog] = useState(null);
  const [success, setSuccess] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    props.api().then(ans => {
      setPdf(ans.pdf_b64);
      setLog(atob(ans.log_b64));
      setSuccess(ans.success);
      if (ans.success) {
        props.callback();
      }
      setFetched(true);
    })
  }, [props.force_refresh])

  if (!fetched) {
    return <LoadingCircle />
  }

  if (success) {
    return (
      <iframe width="100%" src={`data:application/pdf;base64,${pdf}#zoom=140%&toolbar=0&navpanes=0`} />
    )
  }

  return (
    <div>
      <span>Compilazione fallita, ecco il log:</span>
      <pre style={{
        overflow: "auto",
        maxHeight: '400px',
        maxWidth: "100%",
      }}>{log}</pre>
    </div >
  )
}

export default RealTeXLoad;
