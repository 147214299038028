import React, { useEffect } from 'react';
import { CHANGELOG } from '../globals';
import { MdMath } from '../components';
import { useLogin } from '../reducers'
import { login_url } from '../globals'


const Changelog = () => {
  const login = useLogin();
  if (!login.logged_in) {
    login_url()
  }
  useEffect(() => {
    document.title = "Changelog - Owlitrack";
  }, [])

  return (
    <div className="container">
      <MdMath source={CHANGELOG} />
    </div>
  );
}

export default Changelog;
