import React, { Component, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, getIn } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, cloneDeep } from 'lodash'

import { APIError, handle_drf_form_error, } from 'django-rest-react';
import { FormGroupBootstrap, Anteprima, AnteprimaLaTeX, LoadingCircle } from '../components';
import {
  post_new_problema, get_problema_thread,
  patch_new_problema,
  crea_soluzione, modifica_soluzione, delete_soluzione,
  create_bozza, lint_text, build_tex_problema, build_tex_soluzione
} from '../api';
import { displayCategoria, sentry_log, } from '../utils';

import type {
  Categoria, Linter, CategoriaEvento,
  PatchSoluzioneData, ProblemaData as FormValues,
  BozzaProblemaData, SoluzioneData, ThreadProblemGenericConRisposta
} from '../api/types';
import { SOLUZIONE_KEYS } from '../api/types'
import {
  ErrorBlockGeneric, AllegatoMap, PubblicaAllegato,
  TextHelper, MultipleSoluzioniForm, ConfirmedButton,
} from '../components';
import { FONTE, login_url } from '../globals'
import { useAllegato, useCategoria, useCategoriaEvento, useEvento, useLogin } from '../reducers';


enum LintedField {
  Testo = "problema.versioni[0].testo",
  Soluzione = "problema.soluzione_spiegata",
  Commenti = "problema.commenti_correttori"
}

type Linted = {
  [key in LintedField]: Linter[]
}

const empty_initial_values: FormValues = {
  titolo: '',
  problema: {
    titolo: '', allegati: [], categoria: [],
    eventi_proposto: [], soluzioni: [],
    fonte_verboso: "",
    soluzione_spiegata: "",
    commenti_correttori: "",
    fonte: "altro",
    versioni: [{
      testo: '',
    }]
  }
}


enum Button {
  Bozza,
  Invia,
}


const handleSaveDraftFactory = (navigate: (v: string) => void, setError: (v: string) => void) =>
  async (values: FormValues, actions: FormikActions<FormValues>) => {
    const sub_data = cloneDeep(values.problema) as unknown as BozzaProblemaData
    sub_data.testo = values.problema.versioni[0].testo
    // @ts-ignore
    delete sub_data.versioni
    const extraErrParse = (err: any) => {
      const errcp = cloneDeep(err)
      if (errcp.testo) {
        errcp.versioni = [{ testo: errcp.testo }]
      }
      return {
        problema: errcp
      }
    }

    return create_bozza(sub_data as BozzaProblemaData)
      .then(ans => navigate(`/drafts/${ans.id}/`))
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError, extraErrParse))
      .finally(() => actions.setSubmitting(false))
  }


const handleSubmitFactory = (
  navigate: (s: string) => void, setError: (s: string) => void,
  modifica: boolean,
  thread: Awaited<ReturnType<typeof get_problema_thread>>,
  initial: any,
  pk: number) => async (values: FormValues, actions: FormikActions<FormValues>) => {
    let submitting_data = cloneDeep(values);
    let error_happened = false;
    const sol_errors = {
      problema: {
        soluzioni: new Array(values.problema.soluzioni.length)
      }
    }
    const non_field_errors = new Array(values.problema.soluzioni.length)
    const errparse = (error: APIError, idx: number) => {
      error_happened = true;
      if (idx == -1) {
        non_field_errors.push("")
        idx = non_field_errors.length - 1
      }
      handle_drf_form_error(error, actions,
        s => non_field_errors[idx] = s,
        // @ts-ignore
        s => sol_errors.problema.soluzioni[idx] = s)
    }
    if (modifica) {
      // Se è una modifica bisogna fare le seguenti cose:
      // - Controllare se il testo non è cambiato. Se non è cambiato bisogna
      //   cancellare il campo versioni da submitting_data
      // - Fare un confronto ultracancro delle Soluzioni precedenti e di quelle attuali
      //   per ciascuna si guarda inannzitutto se c'è l'id associato, se non c'è vuol dire
      //   che è nuova e sicuramente va sottomessa.
      //   Se c'è bisogna trovare quella vecchia corrispondente, vedere se è cambiata
      //   e in caso fare la patch giusta.

      const { problema } = thread;

      // Confrontiamo il testo
      const last = problema.versioni[problema.versioni.length - 1];
      if (last.testo == values.problema.versioni[0].testo) {
        submitting_data.problema.versioni = []
      };

      // Confrontiamo le soluzioni
      const soluzioni_iniziali = initial.problema.soluzioni as ThreadProblemGenericConRisposta["problema"]["soluzioni"];
      if (values.problema.soluzioni != soluzioni_iniziali) {
        for (let j = 0; j < values.problema.soluzioni.length; j++) {
          const ogg = values.problema.soluzioni[j] as ThreadProblemGenericConRisposta["problema"]["soluzioni"][0];
          if (ogg.id > 0) {
            // Qui devo capire che dati metterci
            let submitting_data_patch: PatchSoluzioneData = {};
            const old = soluzioni_iniziali.find(item => item.id == ogg.id);
            SOLUZIONE_KEYS.forEach(value => {
              // @ts-ignore
              if (old[value] != ogg[value]) submitting_data_patch[value] = ogg[value]
            })
            if (isEmpty(submitting_data_patch)) continue
            await modifica_soluzione(ogg.id, submitting_data_patch)
              .then(() => console.log("Modificato", ogg.id))
              .catch((error: APIError) => errparse(error, j));
          } else {
            let subdata = { problema: thread.problema.id } as SoluzioneData
            // @ts-ignore
            SOLUZIONE_KEYS.forEach(value => subdata[value] = ogg[value])
            await crea_soluzione(subdata)
              .then(sol => console.log("Creata", sol))
              .catch((error: APIError) => errparse(error, j))
          }
        }
        const rimosse = soluzioni_iniziali.filter(item =>
          !(values.problema as ThreadProblemGenericConRisposta["problema"]).soluzioni.map(i => i.id).includes(item.id));
        for (const ogg of rimosse) {
          await delete_soluzione(ogg.id)
            .then(() => console.log("Cancellato", ogg.id))
            .catch((error: APIError) => errparse(error, -1))
        }
      }
      delete submitting_data.problema.soluzioni
    }

    if (error_happened) {
      actions.setErrors(cloneDeep(sol_errors))
      setError("Errore nella modifica delle domande: guarda sopra e qui. È caldamente consigliato salvarti in parte le modifiche fatte al testo, refreshare la pagina e riprovare. Tieni gli errori non riferiti a un singolo campo: " + JSON.stringify(non_field_errors))
      // Volontariamente non resettiamo il setsubmitting per impedire cose insensate
      // sottomettendo due volte
      return
    }

    const call_function = modifica ?
      () => patch_new_problema(pk, submitting_data) :
      () => post_new_problema(submitting_data);
    return call_function()
      .then(risposta => navigate(`/thread/problema/${risposta.id}/`))
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
      .finally(() => actions.setSubmitting(false))
  }




const NuovoThreadProblema = (props: {}) => {

  const login = useLogin();
  const [redirect, setRedirect] = useState(null);
  const [thread, setThread] = useState(null);
  const [error, setError] = useState(null);
  const [initial, setInitial] = useState(empty_initial_values);
  const [lints, setLints] = useState({
    [LintedField.Testo]: [],
    [LintedField.Soluzione]: [],
    [LintedField.Commenti]: [],
  } as Linted);

  const categorieEvento = useCategoriaEvento(props);
  const categorie = useCategoria(props);
  const eventi = useEvento(props);
  const allegati = useAllegato(props);
  const subButton = useRef(Button.Invia);
  const testoRef = useRef(null);
  const lastLint = useRef(new Date());
  const params = useParams<{ pk?: string }>();
  const navigate = useNavigate();

  if (!login.logged_in) {
    login_url()
  }

  const modifica = !!params.pk;
  const pk = modifica ? parseInt(params.pk) : null;

  useEffect(() => {
    if (modifica) {
      document.title = "Modifica problema - Owlitrack";
      get_problema_thread(pk).then(thread => {
        const { problema } = thread;
        const last = problema.versioni[problema.versioni.length - 1];
        const initial = {
          titolo: thread.titolo,
          problema: cloneDeep(problema)
        }
        initial.problema.versioni = [last]
        setThread(thread)
        setInitial(initial)
      })
    } else {
      document.title = "Nuovo problema - Owlitrack";
    }
  }, [modifica, pk])


  if (modifica && !thread) {
    return <LoadingCircle />;
  }

  // Manage errors
  let may_i_hide_json = true
  if (error) {
    if (Object.keys(error).filter(function(string) {
      return string != "titolo" && string != "testo" && string != "versioni" && string != "soluzioni"
    }).length > 0) {
      may_i_hide_json = false;
      // Se error contiene solo i campi già gestiti
      // dal form stesso, non mostrarlo nel blocco errore
    }
  }

  const handleSaveDraft = handleSaveDraftFactory(navigate, setError);
  const handleSubmit = handleSubmitFactory(
    navigate, setError, modifica,
    // @ts-ignore
    initial,
    thread, pk
  );

  const lint = (fieldname: LintedField, values: FormValues, setFieldValue: FormikActions<FormValues>["setFieldValue"], e: Event) => {
    // @ts-ignore
    setFieldValue(fieldname, e.target.value)
    // @ts-ignore
    const newval = e.target.value
    if (new Date().getTime() - lastLint.current.getTime() > 1000 * 10 && !!newval && newval != "") {
      lastLint.current = new Date();
      lint_text(newval).then(linted => setLints(old => {
        return { ...old, [fieldname]: linted }
      }))
    }
  }

  return (
    <div className="container-fluid mb-4">
      <h1>{modifica ? "Modifica thread" : "Nuovo thread"}</h1>
      <Formik
        initialValues={initial} onSubmit={
          (values: FormValues, actions: FormikActions<FormValues>) => {
            let cusumano;
            switch (subButton.current) {
              case Button.Bozza: cusumano = handleSaveDraft(values, actions); break;
              case Button.Invia: cusumano = handleSubmit(values, actions); break;
              default: sentry_log(new Error(
                "Chiamato NuovoThreadProblema::handleSubmission con" +
                ` parametro sbagliato: ${subButton}`));
            }
            cusumano.finally(() => actions.setSubmitting(false));
          }
        }
        enableReinitialize={true}
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit, handleReset }) => {
          const api_problema = modifica ? () => build_tex_problema(thread?.id, { override: values.problema.versioni[0].testo }) : null;
          const api_soluzione = modifica ? () => build_tex_soluzione(thread?.id, { override: values.problema.soluzione_spiegata }) : null;
          return (
            <>
              <Form className="form needs-validation">
                <FormGroupBootstrap
                  name="titolo" type="text" displayName="Titolo"
                />
                <FormGroupBootstrap
                  name="problema.titolo" type="text" displayName="Titolo serio"
                  help_text="Può essere uguale al titolo. Mettine uno diverso solo se il titolo che hai proposto non è adatto ad essere inserito in una gara ufficiale, per esempio se è volgare o è un meme che richiede troppa lore."
                />
                <MultipleSoluzioniForm />
                <FormGroupBootstrap
                  name="problema.categoria" type="select-multiple"
                  displayName="Categoria"
                  choices={categorie}
                  displayChoice={(item: Categoria) => displayCategoria(item.id)}
                  help_text="Tieni premuto Ctrl per selezionare più di uno."
                />
                <FormGroupBootstrap
                  name="problema.eventi_proposto" type="select-multiple"
                  displayName="Eventi proposto"
                  choices={categorieEvento}
                  displayChoice={(item: CategoriaEvento) => item.nome}
                  help_text={"Vuoi proporre questo problema per quali eventi?" +
                    " Tieni premuto Ctrl per selezionare più di uno."}
                />
                <AllegatoMap allegati={values.problema.allegati} />
                <div>
                  <AnteprimaLaTeX fieldname={LintedField.Testo} lints={lints[LintedField.Testo]} latex_api={api_problema} />
                </div>
                <FormGroupBootstrap
                  name={LintedField.Testo} type="textarea"
                  onChange={(e: Event) => lint(LintedField.Testo, values, setFieldValue, e)}
                />
                <FormGroupBootstrap
                  name="problema.fonte" type="select" choices={FONTE}
                  help_text="Da dove ti sei ispirato per scrivere questo problema?"
                />
                <FormGroupBootstrap
                  name="problema.fonte_verboso" type="text"
                  help_text="Dettagli extra sulla fonte del problema. Obbligatorio se hai selezionato 'Altro'"
                />
                <FormGroupBootstrap
                  name={LintedField.Commenti} type="textarea" ref={testoRef}
                  help_text="Commenti che non devono finire nel testo del problema, riservati agli organizzatori e correttori"
                />
                <TextHelper field={testoRef} fieldname={LintedField.Commenti} />
                <div>
                  <Anteprima fieldname={LintedField.Commenti} />
                </div>
                <FormGroupBootstrap
                  name={LintedField.Soluzione} type="textarea"
                  help_text="Come si risolve il problema. Non serve mettere la formula finale, quella va messa quando inserisci una domanda."
                  onChange={(e: Event) => lint(LintedField.Soluzione, values, setFieldValue, e)}
                />
                <div>
                  <AnteprimaLaTeX fieldname={LintedField.Soluzione} lints={lints[LintedField.Soluzione]} latex_api={api_soluzione} />
                </div>
                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  {!modifica &&
                    <button type="button" disabled={isSubmitting}
                      onClick={() => { subButton.current = Button.Bozza; handleSubmit() }}
                      className="btn btn-belize-hole">Salva come bozza</button>
                  }
                  <button type="button" disabled={isSubmitting}
                    onClick={() => { subButton.current = Button.Invia; handleSubmit() }}
                    className="mx-1 btn btn-primary">Invia</button>
                </div>
                <ErrorBlockGeneric error={error} hidejson={may_i_hide_json} />
              </Form>
              <PubblicaAllegato setAllegati={(val) => setFieldValue("problema.allegati", val)} allegatiStr="problema.allegati" />
            </>
          );
        }}
      </Formik>
    </div>
  );
}


export default NuovoThreadProblema;
