import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';

import {
  get_bozza, patch_bozza, delete_bozza,
  post_new_problema,
} from '../api';
import {
  ProblemaData, BozzaProblemaData,
  Categoria, Soluzione, CategoriaEvento
} from '../api/types';
import {
  FormGroupBootstrap, PubblicaAllegato, LoadingCircle, TextHelper, AnteprimaLaTeX,
  ConfirmedButton, MultipleSoluzioniForm, AllegatoMap, ErrorBlockGeneric,
  Anteprima
} from '../components'


import { APIError, handle_drf_form_error } from 'django-rest-react';
import {
  sentry_log, displayCategoria, deserialize_versioned
} from '../utils';
import store from '../store';
import { add_toast_action } from '../actions';
import { FONTE } from '../globals'
import { useAllegato, useCategoria, useCategoriaEvento, useEvento } from '../reducers';


interface FormValues {
  titolo_meme: string,
  titolo: string,
  allegati: number[],
  categoria: number[],
  eventi_proposto: number[],
  testo: string,
  soluzioni: Soluzione[],
  soluzione_spiegata: string,
  commenti_correttori: string,
  problema?: {
    soluzioni: Soluzione[]
  }
}

enum Bottone {
  Save,
  Pubblica,
}


const ModificaBozza = (props: { pk: number }) => {

  const categorieEvento = useCategoriaEvento(props);
  useEvento(props);
  const categorie = useCategoria(props);
  useAllegato(props);

  const [old, setOld] = useState(null);
  const [error, setError] = useState(null);
  const textarea = useRef(null);
  const subButton = useRef(Bottone.Save);
  const navigate = useNavigate();

  useEffect(() => {
    get_bozza(props.pk).then(setOld);
  }, [props.pk])


  const handleUpdate = async (values: FormValues, actions: FormikActions<FormValues>) => {
    return patch_bozza(props.pk, values as unknown as BozzaProblemaData)
      .then(() => {
        store.dispatch(add_toast_action({
          title: "Modifiche salvate",
          message: <>
            Le modifiche alla bozza {values.titolo} sono state correttamente salvate sul server.
          </>,
          time: new Date(),
        }));
      })
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
      .finally(() => actions.setSubmitting(false))
  }

  const handleDelete = async (values: FormValues, actions: FormikActions<FormValues>) => {
    delete_bozza(props.pk)
      .then(() => window.location.assign(`/#/drafts/`))
      .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
  }


  const handlePubblica = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const titolo_thread = values.titolo_meme;
    delete values.titolo_meme;
    let submitting_data = {
      titolo: titolo_thread,
      problema: cloneDeep(values)
    };
    delete submitting_data.problema.testo;
    // @ts-ignore
    submitting_data.problema.versioni = [{
      testo: values.testo
    }]
    submitting_data.problema.soluzioni = cloneDeep(values.problema.soluzioni)
    return post_new_problema(submitting_data as unknown as ProblemaData)
      .then(ans => delete_bozza(props.pk)
        .then(() => navigate(`/thread/problema/${ans.id}/`))
      )
      .catch((error: APIError) => {
        handle_drf_form_error(
          error, actions, setError,
          msg => {
            // @ts-ignore
            const ret = deserialize_versioned(msg.problema);
            if (ret.soluzioni) {
              if (ret.problema) {
                ret.problema.soluzioni = cloneDeep(ret.soluzioni)
              } else {
                ret.problema = { soluzioni: cloneDeep(ret.soluzioni) }
              }
            }
            return ret
          }
        )
      })
  }

  const handleSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    let cusumano;
    switch (subButton.current) {
      case Bottone.Save: cusumano = handleUpdate(values, actions); break;
      case Bottone.Pubblica: cusumano = handlePubblica(values, actions); break;
      default: sentry_log(
        new Error(
          `ModificaBozza::handleSubmit chiamata con il parametro sbagliato: ${subButton}`));
        break;
    }
    cusumano.finally(() => actions.setSubmitting(false));
  }


  if (!old) {
    return <LoadingCircle />
  }
  let initial = cloneDeep(old) as unknown as FormValues;
  initial.soluzioni = [];

  return (
    <div>
      <ErrorBlockGeneric error={error} />
      <Formik
        initialValues={initial} onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit, handleReset, errors }) => {
          return (
            <>
              <Form className="form needs-validation">
                <h3 className="page-header">{values.titolo}</h3>
                <FormGroupBootstrap
                  name="titolo_meme" type="text"
                  displayName="Titolo"
                  help_text="Attenzione, questo campo viene salvato solo ed esclusivamente se pubblichi il problema. Se salvi le modifiche alla bozza, questo campo verrà perso."
                />
                <FormGroupBootstrap
                  name="titolo" type="text"
                  displayName="Titolo serio"
                />
                <AllegatoMap allegati={values.allegati} />
                <span>Se inserisci una domanda, le modifiche verranno salvate solo se pubblichi il problema e non se lo salvi come bozza.</span>
                <MultipleSoluzioniForm />
                <FormGroupBootstrap
                  name="categoria" type="select-multiple"
                  choices={categorie}
                  displayChoice={(item: Categoria) => displayCategoria(item.id)}
                  help_text="Tieni premuto Ctrl per selezionare più di uno."
                />
                <FormGroupBootstrap
                  name="eventi_proposto" type="select-multiple"
                  choices={categorieEvento}
                  displayChoice={(item: CategoriaEvento) => item.nome}
                  help_text={"Vuoi proporre questo problema per quali eventi?" +
                    " Tieni premuto Ctrl per selezionare più di uno."}
                />
                <div>
                  <AnteprimaLaTeX fieldname="testo" />
                </div>
                <FormGroupBootstrap
                  name="testo" type="textarea"
                />
                <FormGroupBootstrap
                  name="fonte" type="select" choices={FONTE}
                  help_text="Da dove ti sei ispirato per scrivere questo problema?"
                />
                <FormGroupBootstrap
                  name="fonte_verboso" type="text"
                  help_text="Dettagli extra sulla fonte del problema. Obbligatorio se hai selezionato 'Altro'"
                />
                <FormGroupBootstrap
                  name="commenti_correttori" type="textarea" ref={textarea}
                  help_text="Commenti che non devono finire nel testo del problema, riservati agli organizzatori e correttori"
                />
                <TextHelper field={textarea} fieldname="commenti_correttori" />
                <div>
                  <Anteprima fieldname="commenti_correttori" />
                </div>
                <FormGroupBootstrap
                  name="soluzione_spiegata" type="textarea"
                  help_text="Come si risolve il problema. Non serve mettere la formula finale, quella va messa quando inserisci una domanda."
                />
                <div>
                  <AnteprimaLaTeX fieldname="soluzione_spiegata" />
                </div>

                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  <ConfirmedButton
                    type="danger"
                    onSubmit={handleDelete}
                  >
                    Elimina la bozza
                  </ConfirmedButton>
                  <button type="button" disabled={initial == values}
                    onClick={() => { subButton.current = Bottone.Save; handleSubmit() }}
                    className="mx-1 btn btn-belize-hole">Salva le modifiche</button>
                  <button type="button" disabled={isSubmitting}
                    onClick={() => { subButton.current = Bottone.Pubblica; handleSubmit() }}
                    className="mx-1 btn btn-primary">Pubblica come problema</button>
                </div>
              </Form>
              <PubblicaAllegato setAllegati={(val) => setFieldValue("allegati", val)} allegatiStr="allegati" />
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default ModificaBozza;
